import React from "react";
import Entrust from "../../pageComponents/entrustUs";

const TermCondition = () => {
  return (
    <>
      <section className="section">
        <div className="container">
          <div className="columns is-desktop">
            <div className="column">
              <div className="heading has-text-left">
                <h1 className="mb-5">
                  <span className="text-color">TERMS </span>& CONDITIONS
                  <span className="text-color">_</span>
                </h1>
                <h3>Overview</h3>
                <p className="mb-5">
                  This website is operated by Talent Individuals. Throughout the
                  site, the terms “we”, “us” and “our” refer to Talent
                  Individuals. Talent Individuals offers this website, including
                  all information, tools and services available from this site
                  to you, the user, conditioned upon your acceptance of all
                  terms, conditions, policies and notices stated here.
                </p>
                <p className="mb-5">
                  By visiting our site and/ or purchasing something from us, you
                  engage in our “Service” and agree to be bound by the following
                  terms and conditions (“Terms and Conditions”, “Terms”),
                  including those additional terms and conditions and policies
                  referenced herein and/or available by hyperlink. These Terms
                  and Conditions apply to all users of the site, including
                  without limitation users who are browsers, vendors, customers,
                  merchants, and/or contributors of content.
                </p>
                <p className="mb-5">
                  Please read these Terms and Conditions carefully before
                  accessing or using our website. By accessing or using any part
                  of the site, you agree to be bound by these Terms and
                  Conditions. If you do not agree to all the terms and
                  conditions of this agreement, then you may not access the
                  website or use any services. If these Terms and Conditions are
                  considered an offer, acceptance is expressly limited to these
                  Terms and Conditions.
                </p>
                <p className="mb-5">
                  Any new features or tools which are added to the current site
                  shall also be subject to the Terms and Conditions. You can
                  review the most current version of the Terms and Conditions at
                  any time on this page. We reserve the right to update, change
                  or replace any part of these Terms and Conditions by posting
                  updates and/or changes to our website. It is your
                  responsibility to check this page periodically for changes.
                  Your continued use of or access to the website following the
                  posting of any changes constitutes acceptance of those
                  changes.
                </p>
                <h3>General Conditions</h3>
                <p className="mb-5">
                  We reserve the right to refuse service to anyone for any
                  reason at any time.
                </p>
                <p className="mb-5">
                  You understand that your content (not including credit card
                  information), may be transferred unencrypted and involve (a)
                  transmissions over various networks; and (b) changes to
                  conform and adapt to technical requirements of connecting
                  networks or devices. Credit card information is always
                  encrypted during transfer over networks.
                </p>
                <p className="mb-5">
                  You agree not to reproduce, duplicate, copy, sell, resell or
                  exploit any portion of the Service, use of the Service, or
                  access to the Service or any contact on the website through
                  which the service is provided, without express written
                  permission by us.
                </p>
                <p className="mb-5">
                  The headings used in this agreement are included for
                  convenience only and will not limit or otherwise affect these
                  Terms.
                </p>
                <h3>Accuracy, Completeness and Timeliness of Information</h3>
                <p className="mb-5">
                  We are not responsible if information made available on this
                  site is not accurate, complete or current. The material on
                  this site is provided for general information only and should
                  not be relied upon or used as the sole basis for making
                  decisions without consulting primary, more accurate, more
                  complete or more timely sources of information. Any reliance
                  on the material on this site is at your own risk.
                </p>
                <p className="mb-5">
                  This site may contain certain historical information.
                  Historical information, necessarily, is not current and is
                  provided for your reference only. We reserve the right to
                  modify the contents of this site at any time, but we have no
                  obligation to update any information on our site. You agree
                  that it is your responsibility to monitor changes to our site.
                </p>
                <h3>Modifications to the Services and Prices</h3>
                <p className="mb-5">
                  Prices for our services are subject to change without notice.
                </p>
                <p className="mb-5">
                  We reserve the right at any time to modify or discontinue the
                  Service (or any part or content thereof) without notice at any
                  time.
                </p>
                <p className="mb-5">
                  We shall not be liable to you or to any third-party for any
                  modification, price change, suspension or discontinuance of
                  the Service.
                </p>
                <h3>Products or Services (if applicable)</h3>
                <p className="mb-5">
                  We reserve the right, but are not obligated, to limit the
                  sales of our Services to any person, geographic region or
                  jurisdiction. We may exercise this right on a case-by-case
                  basis. We reserve the right to limit the quantities of any
                  services that we offer. All descriptions of service pricing
                  are subject to change at any time without notice, at the sole
                  discretion of us.
                </p>
                <h3>Accuracy of Billing and Account Information</h3>
                <p className="mb-5">
                  You agree to provide current, complete and accurate billing
                  and account information for all services. You agree to
                  promptly update your account and other information, including
                  your email address and credit card numbers and expiration
                  dates, so that we can complete your transactions and contact
                  you as needed.
                </p>
                <h3>User Comments, Feedback and Other Submissions</h3>
                <p className="mb-5">
                  If, at our request, you send certain specific submissions (for
                  example contest entries) or without a request from us you send
                  creative ideas, suggestions, proposals, plans, or other
                  materials, whether online, by email, by postal mail, or
                  otherwise (collectively, 'comments'), you agree that we may,
                  at any time, without restriction, edit, copy, publish,
                  distribute, translate and otherwise use in any medium any
                  comments that you forward to us. We are and shall be under no
                  obligation (1) to maintain any comments in confidence; (2) to
                  pay compensation for any comments; or (3) to respond to any
                  comments.
                </p>
                <p className="mb-5">
                  We may, but have no obligation to, monitor, edit or remove
                  content that we determine in our sole discretion are unlawful,
                  offensive, threatening, libelous, defamatory, pornographic,
                  obscene or otherwise objectionable or violates any party’s
                  intellectual property or these Terms and Conditions.
                </p>
                <p className="mb-5">
                  You agree that your comments will not violate any right of any
                  third-party, including copyright, trademark, privacy,
                  personality or other personal or proprietary right. You
                  further agree that your comments will not contain libelous or
                  otherwise unlawful, abusive or obscene material, or contain
                  any computer virus or other malware that could in any way
                  affect the operation of the service or any related website.
                  You may not use a false e-mail address, pretend to be someone
                  other than yourself, or otherwise mislead us or third-parties
                  as to the origin of any comments. You are solely responsible
                  for any comments you make and their accuracy. We take no
                  responsibility and assume no liability for any comments posted
                  by you or any third-party.
                </p>
                <h3>Personal Information</h3>
                <p className="mb-5">
                  Your submission of personal information through the site is
                  governed by our Privacy Policy. To view our Privacy Policy.
                </p>
                <h3>Errors, Inaccuracies and Omissions</h3>
                <p className="mb-5">
                  Occasionally there may be information on our site or in the
                  service that contains typographical errors, inaccuracies or
                  omissions that may relate to service descriptions and pricing.
                  We reserve the right to correct any errors, inaccuracies or
                  omissions, and to change or update information if any
                  information in the Service or on any related website is
                  inaccurate at any time without prior notice.
                </p>
                <p className="mb-5">
                  We undertake no obligation to update, amend or clarify
                  information in the service or on any related website,
                  including without limitation, pricing information, except as
                  required by law. No specified update or refresh date applied
                  in the service or on any related website, should be taken to
                  indicate that all information in the service or on any related
                  website has been modified or updated.
                </p>
                <h3>Prohibited Uses</h3>
                <p className="mb-5">
                  In addition to other prohibitions as set forth in the Terms
                  and Conditions, you are prohibited from using the site or its
                  content: (a) for any unlawful purpose; (b) to solicit others
                  to perform or participate in any unlawful acts; (c) to violate
                  any international regulations, rules, laws, or local
                  ordinances; (d) to infringe upon or violate our intellectual
                  property rights or the intellectual property rights of others;
                  (e) to harass, abuse, insult, harm, defame, slander,
                  disparage, intimidate, or discriminate based on gender, sexual
                  orientation, religion, ethnicity, race, age, national origin,
                  or disability; (f) to submit false or misleading information;
                  (g) to upload or transmit viruses or any other type of
                  malicious code that will or may be used in any way that will
                  affect the functionality or operation of the service or of any
                  related website, other websites, or the Internet; (h) to
                  collect or track the personal information of others; (i) to
                  spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for
                  any obscene or immoral purpose; or (k) to interfere with or
                  circumvent the security features of the service or any related
                  website, other websites, or the Internet. We reserve the right
                  to terminate your use of the service or any related website
                  for violating any of the prohibited uses.
                </p>
                <h3>Disclaimer of Warranties; Limitations of Liability</h3>
                <p className="mb-5">
                  We do not guarantee, represent or warrant that your use of our
                  service will be uninterrupted, timely, secure or error-free.
                </p>
                <p className="mb-5">
                  We do not warrant that the results that may be obtained from
                  the use of the service will be accurate or reliable.
                </p>
                <p className="mb-5">
                  You agree that from time to time we may remove the service for
                  indefinite periods of time or cancel the service at any time,
                  without notice to you.
                </p>
                <p className="mb-5">
                  You expressly agree that your use of, or inability to use, the
                  service is at your sole risk. The service and services
                  delivered to you through the service are (except as expressly
                  stated by us) provided 'as is' and 'as available' for your
                  use, without any representation, warranties or conditions of
                  any kind, either express or implied, including all implied
                  warranties or conditions of merchantability, merchantable
                  quality, fitness for a particular purpose, durability, title,
                  and non-infringement.
                </p>
                <p className="mb-5">
                  In no case shall Talent Individuals, our directors, officers,
                  employees, affiliates, agents, contractors, interns,
                  suppliers, service providers or licensors be liable for any
                  injury, loss, claim, or any direct, indirect, incidental,
                  punitive, special, or consequential damages of any kind,
                  including, without limitation lost profits, lost revenue, lost
                  savings, loss of data, replacement costs, or any similar
                  damages, whether based in contract, tort (including
                  negligence), strict liability or otherwise, arising from your
                  use of any of the service or any products procured using the
                  service, or for any other claim related in any way to your use
                  of the service or any product, including, but not limited to,
                  any errors or omissions in any content, or any loss or damage
                  of any kind incurred as a result of the use of the service or
                  any content (or product) posted, transmitted, or otherwise
                  made available via the service, even if advised of their
                  possibility. Because some states or jurisdictions do not allow
                  the exclusion or the limitation of liability for consequential
                  or incidental damages, in such states or jurisdictions, our
                  liability shall be limited to the maximum extent permitted by
                  law.
                </p>
                <h3>Indemnification</h3>
                <p className="mb-5">
                  You agree to indemnify, defend and hold harmless Talent
                  Individuals and our parent, subsidiaries, affiliates,
                  partners, officers, directors, agents, contractors, licensors,
                  service providers, subcontractors, suppliers, interns and
                  employees, harmless from any claim or demand, including
                  reasonable attorneys’ fees, made by any third-party due to or
                  arising out of your breach of these Terms and Conditions or
                  the documents they incorporate by reference, or your violation
                  of any law or the rights of a third-party.
                </p>

                <h3>Severability</h3>
                <p className="mb-5">
                  In the event that any provision of these Terms and Conditions
                  is determined to be unlawful, void or unenforceable, such
                  provision shall nonetheless be enforceable to the fullest
                  extent permitted by applicable law, and the unenforceable
                  portion shall be deemed to be severed from these Terms and
                  Conditions, such determination shall not affect the validity
                  and enforceability of any other remaining provisions.
                </p>
                <h3>Termination</h3>
                <p className="mb-5">
                  The obligations and liabilities of the parties incurred prior
                  to the termination date shall survive the termination of this
                  agreement for all purposes.
                </p>
                <p className="mb-5">
                  These Terms and Conditions are effective unless and until
                  terminated by either you or us. You may terminate these Terms
                  and Conditions at any time by notifying us that you no longer
                  wish to use our Services, or when you cease using our site.
                </p>
                <p className="mb-5">
                  If in our sole judgement you fail, or we suspect that you have
                  failed, to comply with any term or provision of these Terms
                  and Conditions, we also may terminate this agreement at any
                  time without notice and you will remain liable for all amounts
                  due up to and including the date of termination; and/or
                  accordingly may deny you access to our Services (or any part
                  thereof).
                </p>
                <h3>Entire Agreement</h3>
                <p className="mb-5">
                  The failure of us to exercise or enforce any right or
                  provision of these Terms and Conditions shall not constitute a
                  waiver of such right or provision.
                </p>
                <p className="mb-5">
                  These Terms and Conditions and any policies or operating rules
                  posted by us on this site or in respect to The service
                  constitutes the entire agreement and understanding between you
                  and us and govern your use of the service, superseding any
                  prior or contemporaneous agreements, communications and
                  proposals, whether oral or written, between you and us
                  (including, but not limited to, any prior versions of the
                  Terms and Conditions).
                </p>
                <p className="mb-5">
                  Any ambiguities in the interpretation of these Terms and
                  Conditions shall not be construed against the drafting party.
                </p>
                <h3>Governing Law</h3>
                <p className="mb-5">
                  These Terms and Conditions and any separate agreements whereby
                  we provide you Services shall be governed by and construed in
                  accordance with the laws of the United Kingdom.
                </p>
                <h3>Changes to Terms and Conditions</h3>
                <p className="mb-5">
                  You can review the most current version of the Terms and
                  Conditions at any time at this page. We reserve the right, at
                  our sole discretion, to update, change or replace any part of
                  these Terms and Conditions by posting updates and changes to
                  our website. It is your responsibility to check our website
                  periodically for changes. Your continued use of or access to
                  our website or the Service following the posting of any
                  changes to these Terms and Conditions constitutes acceptance
                  of those changes.
                </p>
                <h3>Contact Information</h3>
                <p>
                  Questions about the Terms and Conditions should be sent to us
                  at info@talentindividuals.net.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Entrust />
    </>
  );
};

export default TermCondition;
