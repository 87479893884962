const Teams = () => {
  return (
    <section className="section team">
      <div className="container">
        <div className="columns is-desktop is-justify-content-center">
          <div className="column is-10-desktop">
            <div className="heading has-text-centered mb-50">
              <h2 className="mb-5">Our Experts</h2>
              <p>
                Our friendly team of experts are always happy to help and are
                dedicated to delivering great results for our clients. At Talent
                individuals we all take great pride in the work we do and are
                constantly developing our skills, so we can always ensure that
                we offer the most current knowledge and competitive service. Put
                names to faces and meet all the team; we like to build strong
                working relationships with our clients, which is why we believe
                it’s important that you get to know the people behind the
                company.
              </p>
            </div>
          </div>
        </div>

        <div className="columns is-multiline">
          <div className="column is-3-desktop is-6-tablet">
            <div className="team-block mb-5 mb-lg-0">
              <img src="../images/team/01-3.jpg" alt="" className="w-100" />

              <h4 className="mt-4 mb-0">Marshal Root</h4>
              <p>Marketing Head</p>
            </div>
          </div>

          <div className="column is-3-desktop is-6-tablet">
            <div className="team-block mb-5 mb-lg-0">
              <img src="../images/team/03-1.jpg" alt="" className="w-100" />

              <h4 className="mt-4 mb-0">Siamon john</h4>
              <p>Co-ordinator</p>
            </div>
          </div>
          <div className="column is-3-desktop is-6-tablet">
            <div className="team-block">
              <img
                src="../images/team/unknown-1.jpg"
                alt=""
                className="w-100"
              />

              <h4 className="mt-4 mb-0">Anu Oluwapo</h4>
              <p>Senior React Developer</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Teams;
