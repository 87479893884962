import { Outlet } from "react-router-dom";
import "./App.css";
import Services from "./ui/main pages/services";
import Footer from "./ui/navigation/footer";
import Header from "./ui/navigation/header";
import CookieConsent from "react-cookie-consent";
function App() {
  const cookieStyle = {
    backgroundColor: "#FF715B",
  };
  return (
    <div className="App">
      <Header />
      <Outlet />
      <Footer />
      <CookieConsent style={cookieStyle}>We are using cookies</CookieConsent>
    </div>
  );
}

export default App;
