import React from "react";
import ContactForm from "../../pageComponents/form";
import SubHeader from "../../pageComponents/subHeader";

const Contact = () => {
  return (
    <>
      <SubHeader title="Contact Us" />
      <section className="contact-form-wrap section">
        <div className="container">
          <div className="columns is-multiline">
            <div className="column is-12">
              <span className="text-color">Send a message</span>
              <h3 className="text-md mb-5">Contact Form</h3>
            </div>
          </div>
          <div className="columns is-multiline">
            <div className="column is-8-desktop">
              <ContactForm />
            </div>

            <div className="column is-4-desktop">
              <div className="short-info">
                <ul className="list-unstyled">
                  <li>
                    <h5>Call Us</h5>
                    +44 207 642 2814
                  </li>
                  <li>
                    <h5>Email Us</h5>
                    info@talentindividuals.net
                  </li>
                  <li>
                    <h5>London Location</h5>
                    Unit F39 Water Front Studio 1 Dock Road, Canning Town,
                    London, E16 1AH
                  </li>
                </ul>

                <ul className="social-icons list-inline mt-5">
                  <li className="list-inline-item">
                    <a href="http://www. .com">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="http://www. .com">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="http://www. .com">
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
