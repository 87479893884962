import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
const ContactForm = () => {
  const [messageSent, setMessageSent] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_alt115h",
        "template_gbs516f",
        form.current,
        "7AsF8LBXKvDcj4-X4"
      )
      .then(
        (result) => {
          setMessageSent(true);

          setTimeout(() => {
            setMessageSent(false);
          }, 2000);
          e.target.reset();
          setErrorMsg(false);
        },
        (error) => {
          setErrorMsg(true);
        }
      );
  };
  return (
    <form
      id="contact-form"
      className="contact__form"
      ref={form}
      onSubmit={sendEmail}
    >
      <div className="columns is-multiline">
        <div className="column is-12">
          {messageSent && (
            <div
              className="notification is-success contact__msg mb-4"
              role="alert"
            >
              Your message was sent successfully.
            </div>
          )}
          {errorMsg && (
            <div
              className="notification is-success contact__msg mb-4"
              role="alert"
            >
              Your message was not sent, Try Again.
            </div>
          )}

          <select
            className="input"
            id="exampleFormControlSelect1"
            name="Need_Help_in"
          >
            <option>I Need help in ...</option>
            <option>Website Design</option>
            <option>Mobile Application development</option>
            <option>website Development</option>
            <option>Wordpress</option>
            <option>Graphic Design</option>
            <option>SEO</option>
            <option>UX Design</option>
            <option>E Commerce</option>
            <option>Social Media Marketing</option>
            <option>Content</option>
            <option>Business Intelligence and Analytics</option>
            <option>Cyber Security</option>
            <option>Cloud migration</option>
            <option>ETL (extract, transform, load)</option>
            <option>IT Support</option>
            <option>PPC</option>
            <option>Others...</option>
          </select>
        </div>
        <div className="column is-12">
          <input
            name="subject"
            id="subject"
            type="text"
            className="input"
            placeholder="Your Subject"
          />
        </div>
        <div className="column is-12">
          <input
            name="user_name"
            id="name"
            type="text"
            className="input"
            placeholder="Your Name"
          />
        </div>
        <div className="column is-12">
          <input
            name="user_email"
            id="email"
            type="email"
            className="input"
            placeholder="Email Address"
          />
        </div>
        <div className="column is-12">
          <textarea
            name="message"
            id="message"
            className="input"
            placeholder="Your Message"
          ></textarea>
        </div>
        <div className="column is-12">
          <button className="btn btn-main" name="submit" type="submit">
            Send Message
          </button>
        </div>
      </div>
    </form>
  );
};

export default ContactForm;
