const SubHeader = ({ title, subtitle }) => {
  return (
    <section className="page-title bg-1">
      <div className="container">
        <div className="columns">
          <div className="column is-12">
            <div className="block has-text-centered">
              <h3 className="is-capitalize text-lg text-white">{title}</h3>
              <span className="text-white">{subtitle}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SubHeader;
