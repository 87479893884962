import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import portfolioData from "../siteData/portfolioData";
import { Link } from "react-router-dom";
const PortFolioShowcase = () => {
  const responsiveSettings = {
    0: {
      items: 1,
    },
    580: {
      items: 3,
    },
  };
  return (
    <section className="section portfolio pb-0">
      <div className="container">
        <div className="columns">
          <div className="column is-8-desktop">
            <div className="heading">
              <h2 className="mb-5">
                Let's build something great together, Check Our Portfolio
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="columns is-desktop portfolio-gallery">
          <AliceCarousel
            mouseTracking
            infinite
            autoPlayInterval={5000}
            animationDuration={1500}
            disableDotsControls
            disableButtonsControls
            responsive={responsiveSettings}
            autoPlay
          >
            {portfolioData.map((each) => (
              <div className="column is-12-desktop" key={each.id}>
                <div className="portflio-item is-relative mb-4">
                  <Link to={`/portfolio-info/${each.title}`}>
                    <img src={each.img} alt="" className="w-100" />
                    <div className="overlay-item">
                      <i className="ti-link"></i>
                    </div>

                    <div className="portfolio-item-content">
                      <h3 className="mb-0 text-white">{each.title}</h3>
                      <p className="text-white-50">{each.category}</p>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </AliceCarousel>
        </div>
      </div>
    </section>
  );
};

export default PortFolioShowcase;
