import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Services from "./ui/main pages/services";
import Home from "./ui/main pages/home";
import EachServiceDetailPage from "./ui/main pages/eachServicesDetailed";
import Subservicepage from "./ui/main pages/subServicePage";
import About from "./ui/main pages/about";
import Portfolio from "./ui/main pages/portfolio";
import Contact from "./ui/main pages/contact";
import Team from "./ui/main pages/team";
import TermCondition from "./ui/main pages/terms-conditions";
import Privacy from "./ui/main pages/privacy";
import PortfolioDetail from "./ui/main pages/portfolioDetail";
import EachServicePortfolioDetail from "./ui/main pages/eachserportfolioDetail";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="" element={<App />}>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route
            path="/eachservicesdetailed/:servicetitle"
            element={<EachServiceDetailPage />}
          />
          <Route
            path="/subservicepage/:subservice"
            element={<Subservicepage />}
          />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/team" element={<Team />} />
          <Route path="/terms-conditions" element={<TermCondition />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/portfolio-info/:title" element={<PortfolioDetail />} />
          <Route
            path="/portfolio-details/:title"
            element={<EachServicePortfolioDetail />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
