import React from "react";
import SubHeader from "../../pageComponents/subHeader";
import Teams from "../../pageComponents/subServiceContent/teams";

const Team = () => {
  return (
    <>
      <SubHeader title="Meet The Team" />
      <Teams />
    </>
  );
};

export default Team;
