import React from "react";
import { useParams } from "react-router-dom";
import portfolioData from "../../siteData/portfolioData";
import serviceListData from "../../siteData/serviceListData";

const EachServicePortfolioDetail = () => {
  const { title } = useParams();
  let subServiceData = "";
  const getSubListArray = serviceListData.map((item) => item.subServiceList);
  const getSubTitle = getSubListArray.map((item) =>
    item.map((eachPort) =>
      eachPort.subServicePortfolio.find((each) => {
        if (each.title === title) {
          subServiceData = each;
        }
      })
    )
  );
  return (
    <>
      <section className="section case-study">
        <div className="container">
          <div className="columns">
            <div className="columns">
              <div className="column lg-6 column md-8">
                <div className="case-content pl-4 mt-4 mt-lg-0">
                  <h3 className="mb-3">{subServiceData.title}</h3>

                  <p>{subServiceData.desc}</p>

                  <ul className="list-unstyled project-info-list">
                    <li>
                      <strong>Category: </strong>
                      {subServiceData.category}
                      {subServiceData.url && (
                        <p>
                          Website Link:{" "}
                          <a href={subServiceData.url}>{subServiceData.url}</a>
                        </p>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="column lg-6">
                <div className="case-img">
                  <img
                    src={subServiceData.secondimg}
                    alt=""
                    className="w-100"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section pt-0">
        <div className="container">
          <div className="columns">
            <div className="column lg-4 column md-6">
              <div className="mb-5 mb-lg-0">
                <img
                  src="images/about/process-1.jpg"
                  alt=""
                  className="w-100"
                />
                <h4 className="mt-3">Our Strategies</h4>
                <p>
                  Ducimus recusandae molestias, suscipit neque, sit inventore.
                  Totam, adipisci eos numquam libero. Quasi dolore pariatur
                  alias eligendi!
                </p>
              </div>
            </div>

            <div className="column lg-4 column md-6">
              <div className="mb-5 mb-lg-0">
                <img
                  src="images/about/process-2.jpg"
                  alt=""
                  className="w-100"
                />
                <h4 className="mt-3">Our Challenges</h4>
                <p>
                  Eveniet nisi eius qui necessitatibus exercitationem, quam
                  suscipit harum, nostrum reprehenderit nobis error
                </p>
              </div>
            </div>
            <div className="column lg-4 column md-6">
              <div className="mb-0">
                <img
                  src="images/about/process-3.jpg"
                  alt=""
                  className="w-100"
                />
                <h4 className="mt-3">Our Success</h4>
                <p>
                  Eveniet nisi eius qui necessitatibus exercitationem, quam
                  suscipit harum, nostrum reprehenderit nobis error
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EachServicePortfolioDetail;
