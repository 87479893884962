export default [
  {
    id: 1,
    icon: "ti-panel",
    title: "Flexible Engagement Models",
  },
  {
    id: 2,
    icon: "ti-desktop",
    title: "Custom Software Development",
  },
  {
    id: 3,
    icon: "ti-light-bulb",
    title: "Product Innovation & Technology",
  },
  {
    id: 4,
    icon: "ti-mobile",
    title: "Mobility   Service",
  },
  {
    id: 5,
    icon: "ti-check-box",
    title: " Quality  Assurance",
  },
  {
    id: 6,
    icon: "ti-dashboard",
    title: "Enterprise  Solution",
  },
  {
    id: 7,
    icon: "ti-headphone-alt",
    title: "Integrated & Dedicated   Teams",
  },
  {
    id: 8,
    icon: "ti-bar-chart-alt",
    title: " Big data and   analytics",
  },
  {
    id: 5,
    icon: "ti-cloud-up",
    title: " Cloud backup   System",
  },
];
