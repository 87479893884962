import React from "react";
import { useParams } from "react-router-dom";
import portfolioData from "../../siteData/portfolioData";

const PortfolioDetail = () => {
  const { title } = useParams();
  const eachPortfolioData = portfolioData.find(
    (portfolio) => portfolio.title === title
  );
  return (
    <>
      <section className="section case-study">
        <div className="container">
          <div className="columns">
            <div className="columns">
              <div className="column lg-6 column md-8">
                <div className="case-content pl-4 mt-4 mt-lg-0">
                  <h3 className="mb-3">{eachPortfolioData.title}</h3>

                  <p>{eachPortfolioData.desc}</p>

                  <ul className="list-unstyled project-info-list">
                    <li>
                      <strong>Category: </strong>
                      {eachPortfolioData.category}
                      {eachPortfolioData.url && (
                        <p>
                          Website Link:{" "}
                          <a href={eachPortfolioData.url}>
                            {eachPortfolioData.url}
                          </a>
                        </p>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="column lg-6">
                <div className="case-img">
                  <img
                    src={eachPortfolioData.secondimg}
                    alt=""
                    className="w-100"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PortfolioDetail;
