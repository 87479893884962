import React from "react";
import { Link } from "react-router-dom";
import serviceListData from "../siteData/serviceListData";
const ServiceShowCase = () => {
  const serviceLists = serviceListData.map((each, index) => (
    <div className="columns is-align-items-center" key={each.id}>
      <div className="column is-6-desktop">
        <div className="service-img">
          <img src={each.serviceImg} alt="" className="w-100" />
        </div>
      </div>
      <div className="column is-6-desktop">
        <div className="service-info ">
          <span className="text-color has-text-weight-bold">0{index + 1}</span>
          <h3 className="text-md mb-5 mt-3">{each.title}</h3>
          <p className="mb-5">{each.serviceDesc}</p>

          <ul className="service-sub-list pb-5">
            {each.subServiceList.map((each) => (
              <li className="mt-1" key={each.id}>
                {each.title}
              </li>
            ))}
          </ul>

          <Link
            to={`/eachservicesdetailed/${each.title}`}
            className="btn btn-main"
          >
            See More
            <i className="fa fa-angle-right ml-2"></i>
          </Link>
        </div>
      </div>
    </div>
  ));
  return (
    <section className="section service-2">
      <div className="container">{serviceLists}</div>
    </section>
  );
};

export default ServiceShowCase;
