import React from "react";
import { Link } from "react-router-dom";
const MainBanner = () => {
  return (
    <section className="banner is-flex is-align-items-center">
      <div className="banner-img-part"></div>
      <div className="container">
        <div className="columns">
          <div className="column is-8-widescreen is-10-desktop">
            <div className="block">
              <span className="is-uppercase text-sm letter-spacing ">
                The most powerful Solution
              </span>
              <h1 className="my-4">
                Digital experience with Talent Individuals
              </h1>
              <p className="mb-6">
                From web design, development and mobile apps, through to
                marketing, IT, security and software.
              </p>

              <Link to="/contact" className="btn btn-main">
                Work With Us
                <i className="fa fa-angle-right ml-2"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainBanner;
