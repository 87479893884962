import React from "react";
import Entrust from "../../pageComponents/entrustUs";
import HowWeWork from "../../pageComponents/howWeWork";
import ServiceShowCase from "../../pageComponents/serviceShowCaseList";
import StaffSolution from "../../pageComponents/staffSolution";
import SubHeader from "../../pageComponents/subHeader";

const Services = () => {
  const title = "Our Service";
  const subtitle =
    "Talent Individuals is a full service digital agency located in London, United Kindgom. We are a team of professional designers, developers, marketers and IT specialists combining our knowledge and expertise to create exceptional designs and strategies, tailored to your business’s needs and requirements. Tell us about your goal and we’ll show you how you can achieve it. Whatever your project, we’d love to be involved.";
  return (
    <>
      <SubHeader title={title} subtitle={subtitle} />
      <ServiceShowCase />
      <HowWeWork />
      <StaffSolution />
      <Entrust />
    </>
  );
};

export default Services;
