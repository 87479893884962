import React from "react";
import Entrust from "../../pageComponents/entrustUs";
import Testimonials from "../../pageComponents/review";
import SubHeader from "../../pageComponents/subHeader";
import Teams from "../../pageComponents/subServiceContent/teams";

const About = () => {
  return (
    <>
      <SubHeader title="About Talent Individuals" />
      <section className="section ">
        <div className="container">
          <div className="columns is-desktop is-justify-content-center">
            <div className="column is-10-desktop">
              <div className="heading has-text-centered">
                <h2>POSITIVE THINKING AND CREATIVE COMPANY</h2>
                <p>
                  We are a group of highly skilled individuals who share a
                  common love for the work we deliver.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="video-block section bg-light">
        <div className="container">
          <div className="columns is-desktop is-align-items-center">
            <div className="column is-6-desktop">
              <div className="feature-list">
                <h2 className="mb-5">About TalentIndividuals</h2>
                <p>
                  TalentIndividuals was founded back in 2021 in London, and has
                  since expanded to Southampton and Bristol in recent years. We
                  first specialised in database software and report writing.
                  Since then we have expanded to offer a full range of
                  technical, web, software, digital marketing and other IT
                  services for business.
                </p>

                <ul className="list-unstyled mt-5 mb-6">
                  <li>
                    <i className="ti-check mr-4"></i>We constantly strive to
                    maintain and update our knowledge, expertise and service
                    offerings in order to best serve our clients’ needs. Cloud
                    computing, mobile phone app development and search engine
                    marketing are all areas in which we help our clients to
                    expand, cut costs, reduce risk, and improve efficiency.
                    Offering the right advice based on the most current
                    knowledge is vital to the success of Talent Individuals and
                    is something we take great pride in.
                  </li>
                  <li>
                    <i className="ti-check mr-4"></i>We have a long standing
                    track record in our chosen technologies, and we have been
                    working with our diverse client base of Private and Public
                    Sector businesses and organisation since 2005 to deliver web
                    and data solutions across the UK and around the World.
                  </li>
                </ul>
              </div>
            </div>

            <div className="column is-6-desktop">
              <div className="video-img">
                <img src="images/about/img-34.png" alt="" className="w-100" />
                <a data-video-id="sXoKSD8QJEA" className="video-play">
                  <i className="ti-control-play"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Teams />

      <Testimonials />
      <Entrust />
    </>
  );
};

export default About;
