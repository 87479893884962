import React from "react";
import Entrust from "../../pageComponents/entrustUs";

const Privacy = () => {
  return (
    <>
      <section className="section">
        <div className="container">
          <div className="columns is-desktop">
            <div className="column">
              <div className="heading has-text-left">
                <h1 className="mb-5">
                  <span className="text-color">PRIVACY </span> POLICY
                  <span className="text-color">_</span>
                </h1>
                <p className="mb-5">
                  Please review our Privacy Policy regularly as it is subject to
                  intermittent amendment.
                </p>
                <h3>What is this Privacy Policy for?</h3>
                <p className="mb-5">
                  This privacy policy is for this website
                  [https://www.TalentIndividuals.net/] and served by Talent
                  Individuals and governs the privacy of its users who choose to
                  use it.
                </p>
                <p className="mb-5">
                  The policy sets out the different areas where user privacy is
                  concerned and outlines the obligations & requirements of the
                  users, the website and website owners. Furthermore the way
                  this website processes, stores and protects user data and
                  information will also be detailed within this policy.
                </p>
                <h3>The Website</h3>
                <p className="mb-5">
                  This website and its owners take a proactive approach to user
                  privacy and ensure the necessary steps are taken to protect
                  the privacy of its users throughout their visiting experience.
                  This website complies with all UK national laws and
                  requirements for user privacy.
                </p>
                <h3>What information do we collect?</h3>
                <p className="mb-5">
                  We may collect the following personal information:
                  <ul>
                    <li>Your IP address (in server logs)</li>
                    <li>
                      Your email address (where it is provided to us in the
                      contact forms on this website)
                    </li>
                    <li>
                      Your phone number (where it is provided to us in the
                      contact forms on this website)
                    </li>
                  </ul>
                </p>
                <h3>What do we use this information for?</h3>
                <p className="mb-5">
                  Your IP address may be used for diagnostic and forensic
                  reasons on our server or for the purposes of identifying your
                  business name when browsing the site from a corporate network
                  location.
                </p>{" "}
                className="mb-5"
                <p className="mb-5">
                  We will use your name and other contact details for the
                  purposes of answering enquiries.
                </p>
                <h3>How long will we keep your data?</h3>
                <p className="mb-5">
                  Your IP address information will be retained in server logs
                  for 30 days and then deleted.
                </p>
                <p className="mb-5">
                  Email address and contact information will be retained for 7
                  years following completion of any contractual engagement.
                </p>
                <p className="mb-5">
                  Contact information collected for newsletter purposes will be
                  retained until removal is requested by the data subject.
                </p>
                <h3>How can I control the use of my data?</h3>
                <p className="mb-5">
                  You have a wide range of rights as regards your personal data.
                  Under current data protection laws, you have the right to
                  request the following:
                </p>
                <p className="mb-5">
                  The right to erasure of your data (the right to be forgotten).
                </p>
                <p className="mb-5">
                  The right to object (the right to have us stop using your data
                  for a specified purpose)
                </p>
                <p className="mb-5">
                  The right to amendment (the right to have incorrect data
                  amended)
                </p>
                <p className="mb-5">
                  The right to access (to be given a copy of all the data we
                  hold on you in a portable format)
                </p>
                <p className="mb-5">
                  The right to the restriction of processing (to restrict the
                  use of your data)
                </p>
                <p className="mb-5">
                  Rights related to automated processing (we do not conduct any
                  automated processing)
                </p>
                <p className="mb-5">
                  If you have any questions at all about your data or Blue
                  Frontier’s data protection, please use the following contact
                  information:
                </p>
                <p className="mb-5">Email: info@Talent Individuals.co.uk</p>
                <p className="mb-5">
                  Post: Unit 1, The Woodford Centre, Old Sarum, Salisbury,
                  Wiltshire, SP4 6BU
                </p>
                <p className="mb-5">Phone: 01722 744574</p>
                <p className="mb-5">
                  You are also entitled to raise a complaint with the ICO whose
                  details can be found here: www.ico.org
                </p>
                <h3>How do we protect your data?</h3>
                <p className="mb-5">
                  As an ISO 27001:2013 accredited organisation, we already have
                  the foundations of compliance pre-built as the technical and
                  organisational measures required under this ISO accreditation
                  are a good match to meet, and exceed, the test of “reasonable
                  technical and organisational measures” required under the
                  regulation. We are also registered with the ICO as a
                  controller and processor under the terms of the DPA (Data
                  Protection Act).
                </p>
                <p className="mb-5">
                  All our staff receive training on information security and
                  data protection and we take this responsibility very seriously
                  as an organisation.
                </p>
                <h3>Use of Cookies</h3>
                <p className="mb-5">
                  This website uses cookies to better the users experience while
                  visiting the website. Where applicable this website uses a
                  cookie control system allowing the user on their first visit
                  to the website to allow or disallow the use of cookies on
                  their computer / device. This complies with recent legislation
                  requirements for websites to obtain explicit consent from
                  users before leaving behind or reading files such as cookies
                  on a user's computer / device.
                </p>
                <p className="mb-5">
                  Cookies are small files saved to the user's computer’s hard
                  drive that track, save and store information about the user's
                  interactions and usage of the website. This allows the
                  website, through its server to provide the users with a
                  tailored experience within this website.
                </p>
                <p className="mb-5">
                  Users are advised that if they wish to deny the use and saving
                  of cookies from this website on to their computers hard drive
                  they should take necessary steps within their web browsers
                  security settings to block all cookies from this website and
                  its external serving vendors.
                </p>
                <p className="mb-5">
                  This website uses tracking software to monitor its visitors to
                  better understand how they use it. This software is provided
                  by Google Analytics which uses cookies to track visitor usage.
                  The software will save a cookie to your computer’s hard drive
                  in order to track and monitor your engagement and usage of the
                  website, but will not store, save or collect personal
                  information. You can read Google's privacy policy here for
                  further information [http://www.google.com/privacy.html ].
                </p>
                <p className="mb-5">
                  Other cookies may be stored to your computer’s hard drive by
                  external vendors when this website uses referral programs,
                  sponsored links or adverts. Such cookies are used for
                  conversion and referral tracking and typically expire after 30
                  days, though some may take longer. No personal information is
                  stored, saved or collected.
                </p>
                <h3>Contact & Communication</h3>
                <p className="mb-5">
                  Users contacting this website and/or its owners do so at their
                  own discretion and provide any such personal details requested
                  at their own risk. Your personal information is kept private
                  and stored securely until a time it is no longer required or
                  has no use, as detailed in the Data Protection Act 1998. Every
                  effort has been made to ensure a safe and secure form to email
                  submission process but advise users using such form to email
                  processes that they do so at their own risk.
                </p>
                <p className="mb-5">
                  This website and its owners use any information submitted to
                  provide you with further information about the products /
                  services they offer or to assist you in answering any
                  questions or queries you may have submitted. This includes
                  using your details to subscribe you to any email newsletter
                  program the website operates but only if this was made clear
                  to you and your express permission was granted when submitting
                  any form to email process. Or whereby you the consumer have
                  previously purchased from or enquired about purchasing from
                  the company a product or service that the email newsletter
                  relates to. This is by no means an entire list of your user
                  rights in regard to receiving email marketing material. Your
                  details are not passed on to any third parties.
                </p>
                <h3>External Links</h3>
                <p className="mb-5">
                  Although this website only looks to include quality, safe and
                  relevant external links, users are advised adopt a policy of
                  caution before clicking any external web links mentioned
                  throughout this website.
                </p>
                <p className="mb-5">
                  The owners of this website cannot guarantee or verify the
                  contents of any externally linked website despite their best
                  efforts. Users should therefore note they click on external
                  links at their own risk and this website and its owners cannot
                  be held liable for any damages or implications caused by
                  visiting any external links mentioned.
                </p>
                <h3>Adverts and Sponsored Links</h3>
                <p className="mb-5">
                  This website may contain sponsored links and adverts. These
                  will typically be served through our advertising partners, to
                  whom may have detailed privacy policies relating directly to
                  the adverts they serve.
                </p>
                <p className="mb-5">
                  Clicking on any such adverts will send you to the advertiser’s
                  website through a referral program which may use cookies and
                  will track the number of referrals sent from this website.
                  This may include the use of cookies which may in turn be saved
                  on your computer’s hard drive. Users should therefore note
                  they click on sponsored external links at their own risk and
                  this website and its owners cannot be held liable for any
                  damages or implications caused by visiting any external links
                  mentioned.
                </p>
                <h3>Social Media Platforms</h3>
                <p>
                  Communication, engagement and actions taken through external
                  social media platforms that this website and its owners
                  participate on are custom to the terms and conditions as well
                  as the privacy policies held with each social media platform
                  respectively.
                </p>
                <p className="mb-5">
                  Users are advised to use social media platforms wisely and
                  communicate / engage upon them with due care and caution in
                  regard to their own privacy and personal details. This website
                  nor its owners will ever ask for personal or sensitive
                  information through social media platforms and encourage users
                  wishing to discuss sensitive details to contact them through
                  primary communication channels such as by telephone or email.
                </p>
                <p className="mb-5">
                  This website may use social sharing buttons which help share
                  web content directly from web pages to the social media
                  platform in question. Users are advised before using such
                  social sharing buttons that they do so at their own discretion
                  and note that the social media platform may track and save
                  your request to share a web page respectively through your
                  social media platform account.
                </p>
                <h3>Shortened Links in Social Media</h3>
                <p className="mb-5">
                  This website and its owners through their social media
                  platform accounts may share web links to relevant web pages.
                  By default some social media platforms shorten lengthy urls
                  [web addresses] (this is an example: http://bit.ly/zyVUBo).
                </p>
                <p className="mb-5">
                  Users are advised to take caution and good judgement before
                  clicking any shortened urls published on social media
                  platforms by this website and its owners. Despite the best
                  efforts to ensure only genuine urls are published many social
                  media platforms are prone to spam and hacking and therefore
                  this website and its owners cannot be held liable for any
                  damages or implications caused by visiting any shortened
                  links.
                </p>
                <p className="mb-5">
                  Please do not hesitate in contacting us regarding this Privacy
                  Policy at info@TalentIndividuals.net.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Entrust />
    </>
  );
};

export default Privacy;
