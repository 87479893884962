const HowWeWork = () => {
  return (
    <section className="section case-study">
      <div className="container">
        <div className="columns is-justify-content-center">
          <div className="column is-8-desktop">
            <div className="case-study-content has-text-centered mb-6">
              <h2 className="mb-5">How we works</h2>
              <p>
                There are many variations of lorem passages of Lorem Ipsum
                available, but the majority have suffered. All the Lorem Ipsum
                generators.
              </p>
            </div>
          </div>
        </div>

        <div className="case-timeline">
          <div className="case-timeline-divider"></div>
          <div className="case-timeline-dot"></div>

          <div className="columns is-align-items-center">
            <div className="column is-6-desktop">
              <div className="case-img ">
                <img
                  src="images/about/process-3.jpg"
                  alt=""
                  className="w-100"
                />
              </div>
            </div>
            <div className="column is-6-desktop">
              <div className="case-content">
                <h4 className="mb-4">Competitor Research</h4>
                <p>
                  Nihil fugit officia esse vero, animi tenetur ullam, dolor
                  aperiam minus aliquid enim laudantium fuga! Commodi voluptas,
                  deleniti distinctio quam totam vitae.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="case-timeline">
          <div className="case-timeline-divider"></div>
          <div className="case-timeline-dot"></div>
          <div className="columns is-align-items-center">
            <div className="column is-6-desktop">
              <div className="case-content">
                <h4 className="mb-4">Making Functional Strategy</h4>
                <p>
                  Nihil fugit officia esse vero, animi tenetur ullam, dolor
                  aperiam minus aliquid enim laudantium fuga! Commodi voluptas,
                  deleniti distinctio quam totam vitae.
                </p>
              </div>
            </div>
            <div className="column is-6-desktop">
              <div className="case-img">
                <img
                  src="images/about/process-2.jpg"
                  alt=""
                  className="w-100"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="case-timeline">
          <div className="case-timeline-divider"></div>
          <div className="case-timeline-dot"></div>

          <div className="columns is-align-items-center">
            <div className="column is-6-desktop">
              <div className="case-img">
                <img
                  src="images/about/process-1.jpg"
                  alt=""
                  className="w-100"
                />
              </div>
            </div>
            <div className="column is-6-desktop">
              <div className="case-content">
                <h4 className="mb-4">Project Outline</h4>
                <p>
                  Nihil fugit officia esse vero, animi tenetur ullam, dolor
                  aperiam minus aliquid enim laudantium fuga! Commodi voluptas,
                  deleniti distinctio quam totam vitae.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="case-timeline">
          <div className="case-timeline-divider"></div>
          <div className="case-timeline-dot"></div>

          <div className="columns is-align-items-center">
            <div className="column is-6-desktop">
              <div className="case-content">
                <h4 className="mb-4">Final Delivery</h4>
                <p>
                  Nihil fugit officia esse vero, animi tenetur ullam, dolor
                  aperiam minus aliquid enim laudantium fuga! Commodi voluptas,
                  deleniti distinctio quam totam vitae.
                </p>
              </div>
            </div>

            <div className="column is-6-desktop">
              <div className="case-img">
                <img
                  src="images/about/structure_sass.png"
                  alt=""
                  className="w-100"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowWeWork;
