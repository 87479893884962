import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

const Header = () => {
  const [toggle, setToggle] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  return (
    <div id="navbar" className="navigation py-4">
      <div className="container">
        <nav className="navbar">
          <div className="navbar-brand">
            <Link className="navbar-item" to="/">
              <img src="/logo.png" alt="logo" />
            </Link>
            <button
              role="button"
              className={
                toggle
                  ? "navbar-burger is-active burger"
                  : "navbar-burger burger"
              }
              data-hidden="true"
              data-target="navigation"
              onClick={() => setToggle(!toggle)}
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </button>
          </div>

          <div
            className={toggle ? "navbar-menu is-active" : "navbar-menu"}
            id="navigation"
          >
            <ul className="navbar-start ml-auto">
              <li className="navbar-item has-dropdown">
                <a
                  className="navbar-link"
                  onClick={() => setDropdown(!dropdown)}
                >
                  Company
                </a>
                <div
                  className={
                    dropdown ? "navbar-dropdown show" : "navbar-dropdown"
                  }
                >
                  <Link className="navbar-item" to="/about">
                    About Us
                  </Link>
                  <Link className="navbar-item" to="/team">
                    Meet The Team
                  </Link>
                </div>
              </li>
              <li className="navbar-item">
                <Link className="navbar-link" to="/services">
                  Service
                </Link>
              </li>

              <li className="navbar-item">
                <Link className="navbar-link" to="/portfolio">
                  Our Work
                </Link>
              </li>

              <li className="navbar-item">
                <Link className="navbar-link" to="/contact">
                  Contact
                </Link>
              </li>
            </ul>
            <ul className="navbar-end ml-0">
              <li className="navbar-item">
                <Link to="/contact" className="btn btn-solid-border">
                  Get an estimate <i className="fa fa-angle-right ml-2"></i>
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
