import { Link } from "react-router-dom";

const HomePageServices = () => {
  return (
    <section className="section process">
      <div className="container">
        <div className="columns is-multiline is-desktop is-align-items-center">
          <div className="column is-5-desktop">
            <div className="process-block pl-4">
              <span className="is-uppercase text-sm letter-spacing">
                Our Service
              </span>
              <h2 className="mb-4 mt-3">We help you to make work easy</h2>
              <p className="mb-4">
                We are a team of professional designers, developers, marketers
                and IT specialists combining our knowledge and expertise to
                create exceptional designs and strategies, tailored to your
                business’s needs and requirements.
              </p>
              <p className="mb-4">
                Tell us about your goal and we’ll show you how you can achieve
                it. Whatever your project, we’d love to be involved.
              </p>

              <Link to="/services" className="btn btn-main">
                Services
                <i className="fa fa-angle-right ml-2"></i>
              </Link>
            </div>
          </div>

          <div className="column is-7-desktop">
            <div className="columns is-multiline custom-grid">
              <div className="column is-6-widescreen is-6-tablet">
                <div className="icon-block has-text-centered">
                  <i className="ti-light-bulb"></i>
                  <h5>Design</h5>
                  <p>
                    Exceptional design services with a focus on user experience.
                    The design team provide website design, graphic design,
                    video, animation and 3D modelling services.
                  </p>
                </div>
              </div>

              <div className="column is-6-widescreen is-6-tablet pb-0">
                <div className="icon-block has-text-centered mt-30">
                  <i className="ti-panel"></i>
                  <h5>Digital Marketing</h5>
                  <p>
                    Our agency has a team of digital marketing specialists
                    offering SEO, PPC, social media, content marketing and
                    conversion rate optimisation support.
                  </p>
                </div>
              </div>

              <div className="column is-6-widescreen is-6-tablet pt-0">
                <div className="icon-block has-text-centered">
                  <i className="ti-search"></i>
                  <h5>Technical Services</h5>
                  <p>
                    Specialist IT engineers and analysts sharing their expertise
                    with businesses to provide consultancy, IT support, Cloud
                    Support, Cyber Security and Lots More.
                  </p>
                </div>
              </div>

              <div className="column is-6-widescreen is-6-tablet pt-0">
                <div className="icon-block has-text-centered mt-30">
                  <i className="ti-rocket"></i>
                  <h5>Specialist Development Services</h5>
                  <p>
                    We use a wide range of tools to create websites, web portals
                    and applications that are easy to use and maintain.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomePageServices;
