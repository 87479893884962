import React from "react";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <footer className="footer section">
      <div className="container">
        <div className="columns is-multiline">
          <div className="column is-3-widescreen is-6-tablet">
            <div className="widget">
              <div className="logo mb-4">
                <h3>
                  Talent <br />
                  Individuals
                </h3>
              </div>
              <p>
                We offer a full end-to-end service, specialising in web design,
                development, digital marketing, social media marketing, mobile
                phone app development, cloud computing IT support and other IT
                services.
              </p>
            </div>
          </div>
          <div className="column is-2-widescreen is-6-desktop is-6-tablet ml-auto">
            <div className="widget">
              <h4 className="is-capitalize mb-4">Company</h4>

              <ul className="list-unstyled footer-menu lh-35">
                <li>
                  <Link to="/about" href="#">
                    About
                  </Link>
                </li>
                <li>
                  <Link to="/services" href="#">
                    Services
                  </Link>
                </li>
                <li>
                  <Link to="/team" href="#">
                    Team
                  </Link>
                </li>
                <li>
                  <Link to="/contact" href="#">
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="column is-3-widescreen is-6-desktop is-6-tablet">
            <div className="widget">
              <h4 className="is-capitalize mb-4">Support</h4>

              <ul className="list-unstyled footer-menu lh-35">
                <li>
                  <Link to="/terms-conditions">Terms & Conditions</Link>
                </li>
                <li>
                  <Link to="/privacy">Privacy Policy</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="column is-3-widescreen is-6-desktop is-6-tablet">
            <div className="widget widget-contact">
              <h4 className="is-capitalize mb-4">Get in Touch</h4>
              <h6>
                <a href="tel:+23-345-67890">
                  {" "}
                  <i className="ti-headphone-alt mr-3"></i>
                  info@talentindividuals.net
                </a>
              </h6>
              <h6>
                <a href="mailto:support@gmail.com">
                  <i className="ti-mobile mr-3"></i>+44 207 642 2814
                </a>
              </h6>

              <ul className="list-inline footer-socials mt-5">
                <li className="list-inline-item">
                  <a href="https://www.facebook.com/">
                    <i className="ti-facebook mr-2"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="https://twitter.com/talentindividu">
                    <i className="ti-twitter mr-2"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="https://www.linkedin.com/in/talentindividuals">
                    <i className="ti-linkedin mr-2 "></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="https://www.instagram.com/talentindividuals">
                    <i className="ti-instagram mr-2 "></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="footer-btm py-4 mt-6">
          <div className="columns">
            <div className="column is-6-widescreen">
              <div className="copyright">
                &copy; Copyright Reserved to{" "}
                <span className="text-color">Talent Individual</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
