import { Link } from "react-router-dom";
const Entrust = () => {
  return (
    <section className="cta-2 bg-light">
      <div className="container">
        <div className="cta-block py-6">
          <div className="columns is-desktop is-align-items-center ">
            <div className="column is-8-desktop">
              <span className="text-color">For Every type business</span>
              <h2 className="mt-3">
                Entrust your project to our best team of professionals
              </h2>
            </div>
            <div className="column is-4-desktop has-text-right-desktop">
              <Link to="/contact" className="btn btn-main btn-round-full">
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Entrust;
