import { Link } from "react-router-dom";
const Portfolio = () => {
  return (
    <section className="section cta">
      <div className="container">
        <div className="columns is-desktop">
          <div className="column is-5-widescreen is-7-desktop">
            <div className="cta-content bg-white rounded">
              <h3 className="mb-5">
                Assurance Service In any
                <span className="text-color-primary"> Digital Services</span>
              </h3>
              <p className="mb-30">
                An Independent Validation and Testing services from SISAR. Helps
                to reduce software development efforts
              </p>

              <Link to="/portfolio" className="btn btn-main">
                Portfolio<i className="fa fa-angle-right ml-2"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
