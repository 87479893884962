import React from "react";
import portfolioData from "../../siteData/portfolioData";
import { Link } from "react-router-dom";
const Portfolio = () => {
  return (
    <>
      <section className="section portfolio">
        <div className="container">
          <div className="columns is-justify-content-center">
            <div className="column is-8-desktop">
              <div className="heading has-text-centered">
                <h2 className="mb-5">
                  We've worked on so many great projects for our clients and
                  we're proud of every single one.
                </h2>
                <p>
                  We have the best experts to elevate your business to the next
                  level, try is and you will see! We have the best experts to
                  elevate your
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <h1 className="mb-5">
            <span className="text-color">Web Design &</span> Ui Design
          </h1>
          <div className="columns is-multiline">
            {portfolioData
              .filter((port) => port.category == "UI DESIGN")
              .map((each) => (
                <div className="column is-4-desktop is-6-tablet">
                  <div className="portflio-item is-relative">
                    <Link to={`/portfolio-info/${each.title}`}>
                      <img src={each.img} alt="" className="w-100" />
                      <div className="overlay-item">
                        <i className="ti-link"></i>
                      </div>
                      <div className="portfolio-item-content">
                        <h3 className="mb-0 text-white">{each.title}</h3>
                        <p className="text-white-50">{each.category}</p>
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
          </div>
        </div>

        <div className="container">
          <h1 className="mt-5 mb-5">
            <span className="text-color">Website</span> Development
          </h1>
          <div className="columns is-multiline">
            {portfolioData
              .filter((port) => port.category == "Website Development")
              .map((each) => (
                <div className="column is-4-desktop is-6-tablet">
                  <div className="portflio-item is-relative">
                    <Link to={`/portfolio-info/${each.title}`}>
                      <img src={each.img} alt="" className="w-100" />
                      <div className="overlay-item">
                        <i className="ti-link"></i>
                      </div>
                      <div className="portfolio-item-content">
                        <h3 className="mb-0 text-white">{each.title}</h3>
                        <p className="text-white-50">{each.category}</p>
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="container">
          <h1 className="mt-5 mb-5">
            <span className="text-color">E - </span>Commerce
          </h1>
          <div className="columns is-multiline">
            {portfolioData
              .filter((port) => port.category == "E-Commerce")
              .map((each) => (
                <div className="column is-4-desktop is-6-tablet">
                  <div className="portflio-item is-relative">
                    <Link to={`/portfolio-info/${each.title}`}>
                      <img src={each.img} alt="" className="w-100" />
                      <div className="overlay-item">
                        <i className="ti-link"></i>
                      </div>
                      <div className="portfolio-item-content">
                        <h3 className="mb-0 text-white">{each.title}</h3>
                        <p className="text-white-50">{each.category}</p>
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="container">
          <h1 className="mt-5 mb-5">
            <span className="text-color">Graphic</span> Design
          </h1>
          <div className="columns is-multiline">
            {portfolioData
              .filter((port) => port.category == "Graphic design")
              .map((each) => (
                <div className="column is-4-desktop is-6-tablet">
                  <div className="portflio-item is-relative">
                    <Link to={`/portfolio-info/${each.title}`}>
                      <img src={each.img} alt="" className="w-100" />
                      <div className="overlay-item">
                        <i className="ti-link"></i>
                      </div>
                      <div className="portfolio-item-content">
                        <h3 className="mb-0 text-white">{each.title}</h3>
                        <p className="text-white-50">{each.category}</p>
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
          </div>
        </div>

        <div className="container">
          <h1 className="mt-5 mb-5">
            <span className="text-color">Mobile</span> Application
          </h1>
          <div className="columns is-multiline">
            {portfolioData
              .filter((port) => port.category == "Mobile Application")
              .map((each) => (
                <div className="column is-4-desktop is-6-tablet">
                  <div className="portflio-item is-relative">
                    <Link to={`/portfolio-info/${each.title}`}>
                      <img src={each.img} alt="" className="w-100" />
                      <div className="overlay-item">
                        <i className="ti-link"></i>
                      </div>
                      <div className="portfolio-item-content">
                        <h3 className="mb-0 text-white">{each.title}</h3>
                        <p className="text-white-50">{each.category}</p>
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="container">
          <h1 className="mt-5 mb-5">
            <span className="text-color">SEO</span>
          </h1>
          <div className="columns is-multiline">
            {portfolioData
              .filter((port) => port.category == "SEO")
              .map((each) => (
                <div className="column is-4-desktop is-6-tablet">
                  <div className="portflio-item is-relative">
                    <Link to={`/portfolio-info/${each.title}`}>
                      <img src={each.img} alt="" className="w-100" />
                      <div className="overlay-item">
                        <i className="ti-link"></i>
                      </div>
                      <div className="portfolio-item-content">
                        <h3 className="mb-0 text-white">{each.title}</h3>
                        <p className="text-white-50">{each.category}</p>
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="container">
          <h1 className="mt-5 mb-5">
            <span className="text-color">Wordpress</span>
          </h1>
          <div className="columns is-multiline">
            {portfolioData
              .filter((port) => port.category == "Wordpress")
              .map((each) => (
                <div className="column is-4-desktop is-6-tablet">
                  <div className="portflio-item is-relative">
                    <Link to={`/portfolio-info/${each.title}`}>
                      <img src={each.img} alt="" className="w-100" />
                      <div className="overlay-item">
                        <i className="ti-link"></i>
                      </div>
                      <div className="portfolio-item-content">
                        <h3 className="mb-0 text-white">{each.title}</h3>
                        <p className="text-white-50">{each.category}</p>
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="container">
          <h1 className="mt-5 mb-5">
            <span className="text-color">Pay Per </span>Services
          </h1>
          <div className="columns is-multiline">
            {portfolioData
              .filter((port) => port.category == "Pay Per Services")
              .map((each) => (
                <div className="column is-4-desktop is-6-tablet">
                  <div className="portflio-item is-relative">
                    <Link to={`/portfolio-info/${each.title}`}>
                      <img src={each.img} alt="" className="w-100" />
                      <div className="overlay-item">
                        <i className="ti-link"></i>
                      </div>
                      <div className="portfolio-item-content">
                        <h3 className="mb-0 text-white">{each.title}</h3>
                        <p className="text-white-50">{each.category}</p>
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="container">
          <h1 className="mt-5 mb-5">
            <span className="text-color">Social</span> Media
          </h1>
          <div className="columns is-multiline">
            {portfolioData
              .filter((port) => port.category == "Social Media")
              .map((each) => (
                <div className="column is-4-desktop is-6-tablet">
                  <div className="portflio-item is-relative">
                    <Link to={`/portfolio-info/${each.title}`}>
                      <img src={each.img} alt="" className="w-100" />
                      <div className="overlay-item">
                        <i className="ti-link"></i>
                      </div>
                      <div className="portfolio-item-content">
                        <h3 className="mb-0 text-white">{each.title}</h3>
                        <p className="text-white-50">{each.category}</p>
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Portfolio;
