import staffSolutionData from "../siteData/staffSolutionData";

const StaffSolution = () => {
  const staffSolutionList = staffSolutionData.map((each) => (
    <div className="column  is-4-widescreen is-6-tablet">
      <div className="service-item-list py-5" key={each.id}>
        <i className={each.icon}></i>
        <p>{each.title}</p>
      </div>
    </div>
  ));
  return (
    <section className="section service">
      <div className="container">
        <div className="columns">
          <div className="column is-10-desktop">
            <div className="heading">
              <h2 className="mb-5">
                Industry Leading Managed <br />
                Services & Staffing Solutions
              </h2>
              <p>
                Quos recusandae dolore, consequuntur nemo hic nisi perferendis.
                Vel praesentium magnam architecto delectus blanditiis
                doloremque, deleniti aperiam ab adipisci!
              </p>
            </div>
          </div>
        </div>
        <div className="columns is-multiline is-align-items-center">
          {staffSolutionList}
        </div>
      </div>
    </section>
  );
};

export default StaffSolution;
