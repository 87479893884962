import React from "react";
import Features from "../../pageComponents/features";
import MainBanner from "../../pageComponents/mainBanner";
import Portfolio from "../../pageComponents/portfolio";
import PortFolioShowcase from "../../pageComponents/portfolioDetail";
import Testimonials from "../../pageComponents/review";
import HomePageServices from "../../pageComponents/HomePageServices";
import Entrust from "../../pageComponents/entrustUs";

const Home = () => {
  return (
    <>
      <MainBanner />
      <Features />
      <HomePageServices />
      <Portfolio />
      <PortFolioShowcase />
      <Testimonials />
      <Entrust />
    </>
  );
};

export default Home;
