import { useParams } from "react-router-dom";
import CaseStudy from "../../pageComponents/casestudy";
import Entrust from "../../pageComponents/entrustUs";
import SubHeader from "../../pageComponents/subHeader";
import Teams from "../../pageComponents/subServiceContent/teams";
import serviceListData from "../../siteData/serviceListData";
import { Link } from "react-router-dom";
const Subservicepage = () => {
  const { subservice: servicetitle } = useParams();
  let subServiceData = "";
  const getSubListArray = serviceListData.map((item) => item.subServiceList);
  const getSubTitle = getSubListArray.map((item) =>
    item.find((service) => {
      if (service.title === servicetitle) {
        subServiceData = service;
      }
    })
  );
  console.log(subServiceData.title);
  const title = servicetitle;
  return (
    <>
      <SubHeader
        title={servicetitle}
        subtitle={subServiceData.subServiceSubtitle}
      />
      <section className="section">
        <div className="container">
          <div className="columns is-desktop is-justify-content-center">
            <div className="column is-10-desktop">
              <div className="heading has-text-centered">
                <h3>{subServiceData.subServiceMainTitle}</h3>
                <p>{subServiceData.subServiceMainDesc}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="video-block section bg-light">
        <div className="container">
          <div className="columns is-desktop is-align-items-center">
            <div className="column is-6-desktop">
              <div className="feature-list">
                <h2 className="mb-5">
                  {subServiceData.subServiceFirstSectionTitle}
                </h2>
                <p>{subServiceData.subServiceFirstSectionDesc}</p>
                {subServiceData.subServiceFirstSectionLinkTitle && (
                  <Link
                    to={subServiceData.subServiceFirstSectionLinkUrl}
                    className="btn btn-main"
                  >
                    {subServiceData.subServiceFirstSectionLinkTitle}
                    <i className="fa fa-angle-right ml-3"></i>
                  </Link>
                )}
              </div>
            </div>

            <div className="column is-6-desktop">
              <div className="video-img">
                <img
                  src={subServiceData.subServiceFirstSectionImg}
                  alt=""
                  className="w-100"
                />
                <a data-video-id="sXoKSD8QJEA" className="video-play">
                  <i className="ti-control-play"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section cta">
        <div className="container">
          <div className="columns is-desktop">
            <div className="column is-5-widescreen is-7-desktop">
              <div className="cta-content bg-white rounded">
                <h3 className="mb-5">
                  {subServiceData.subServiceHowWeWorkTitle}
                </h3>
                <p className="mb-30">
                  {subServiceData.subServiceHowWeWorkDesc}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section case-study">
        <div className="container">
          <div className="case-timeline">
            <div className="case-timeline-divider"></div>
            <div className="case-timeline-dot"></div>

            <div className="columns is-align-items-center">
              <div className="column is-6-desktop">
                <div className="case-img ">
                  <img
                    src={subServiceData.otherServiceImg}
                    alt=""
                    className="w-100"
                  />
                </div>
              </div>
              <div className="column is-6-desktop">
                <div className="case-content">
                  <h4 className="mb-4">{subServiceData.otherServiceTitle}</h4>
                  <p>{subServiceData.otherServiceDesc}</p>
                  <Link
                    to={subServiceData.otherServiceLinkUrl}
                    href="project.html"
                    className="btn btn-main mt-4"
                  >
                    {subServiceData.otherServiceLinkTitle}
                    <i className="fa fa-angle-right ml-2"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* START TEAMS */}
      <Teams />
      {/* END TEAMS */}

      {/* Case Study Start*/}

      <CaseStudy
        title={subServiceData.title}
        data={subServiceData.subServicePortfolio}
      />
      {/* Case Study End*/}

      <Entrust />
    </>
  );
};

export default Subservicepage;
