import React from "react";

const Features = () => {
  return (
    <section className="section about">
      <div className="container">
        <div className="columns is-multiline">
          <div className="column is-4-widescreen is-6-tablet">
            <div className="about-item">
              <div className="icon">
                <i className="ti-light-bulb"></i>
              </div>

              <div className="content">
                <h4 className="mt-3 mb-3">
                  Custom Effective Software development
                </h4>
              </div>
            </div>
          </div>

          <div className="column is-4-widescreen is-6-tablet">
            <div className="about-item">
              <div className="icon">
                <i className="ti-panel"></i>
              </div>
              <div className="content">
                <h4 className="mt-3 mb-3">
                  Analyse Your Expensees On Every Device
                </h4>
              </div>
            </div>
          </div>

          <div className="column is-4-widescreen is-6-tablet">
            <div className="about-item">
              <div className="icon">
                <i className="ti-headphone-alt"></i>
              </div>
              <div className="content">
                <h4 className="mt-3 mb-3">
                  Creating a dedicated IT business gcolumnsth
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
