export default [
  {
    id: 1,
    serviceImg: "images/service/service-2.jpg",
    title: "Design",
    serviceDetailDescTitle:
      "We are a team of experienced designers focused on how a project looks, performs, and works to engage users.",
    serviceDetailDescDesc:
      "We plan and deliver exceptional design projects, from bespoke photography and video to engaging graphics and high-performance websites. Through close collaboration, our specialists can work together to provide a comprehensive package of design services, which allows us to ensure consistent branding and quality and means we can support you with all of your design needs under one roof.",
    serviceDesc:
      "Exceptional design services with a focus on user experience. The design team provide website design, graphic design, video,animation and 3D modelling services.",
    subServiceList: [
      {
        id: 1,
        title: "Web Design",
        desc: "You'll receive an engaging, easy to navigate website that's focused towards your target audience. We'll help turn visitors into customers with a strong design, a carefully planned user journey experience and clear calls to action.",
        subServiceSubtitle:
          "We've been capturing and engaging users with stunning websites for over fifteen years",
        subServiceMainTitle:
          "Take your users on a journey by giving them the best experience on your website.",
        subServiceMainDesc:
          "Captivate users with awesome visuals and keep them immersed in your site by making it easy for them to find exactly what they want. The look and feel of a website will inspire, but user-friendliness is essential to achieving success. As a UK based Web Design Agency, we firmly believe that balancing these two aspects of design will result in the greatest user experience.Whether you want it to convert leads, make sales or drive traffic, your website will be designed by our expert web designers to achieve your goals.",
        subServiceFirstSectionTitle: "Designing a website for the end user",
        subServiceFirstSectionImg: "../images/about/webdesign.jpg",
        subServiceFirstSectionDesc:
          "A website should be engaging, easy to navigate and focused on your target audience. The user journey dictates whether you convert a prospect into a profitable customer. Each decision you make about the design will affect how a user interacts with the page. You’ve got to include the right information so that users can make informed decisions and your calls to action should drive them toward a key goal. Our webpage designers can create a website that puts your users first. We’ll guide you on the best features and create user-focused designs with smooth navigation to make sure your website converts.",
        subServiceFirstSectionLinkTitle: "UX Design",
        subServiceFirstSectionLinkUrl: "/subservicepage/UX%20Design",
        subServiceHowWeWorkTitle: "How we work with you",
        subServiceHowWeWorkDesc:
          "With every mock-up, prototype and design concept, we’ll get your feedback so that you stay at the heart of the design process. We find that collaboration between you and our web design and development team is the most effective way to drive success. So, you’ll be able to chat to the team as much you need to fine tune those designs and complete your website.",

        otherServiceImg: "../images/about/web-mobile.jpg",
        otherServiceTitle: "Mobile-first optimised websites",
        otherServiceLinkTitle: "RESPONSIVE WEB DESIGN",
        otherServiceLinkUrl: "/subservicepage/Responsive",
        otherServiceDesc:
          "Over 1/3 of UK search traffic comes exclusively from mobile devices, and with Google moving toward a mobile-first index, it goes without saying that your website must be mobile responsive, and our web design service offers that and more. It’s essential that your users have a consistent experience with your website no matter what device they use. As a web design agency, we carefully consider how designs look and how easy and intuitive they are to navigate on the myriad of devices available.",
        subServicePortfolio: [
          {
            id: 1,
            img: "../images/portfolio/uidesign-1-1.jpg",
            secondimg: "../images/portfolio/uidesign1-2.png",
            title: "Crypto Portfolio Mobile App - Landing Page",
            category: "UI DESIGN",
            desc: "",
            link: "",
          },
          {
            id: 2,
            img: "../images/portfolio/webdesign1.jpg",
            secondimg: "../images/portfolio/webdesign-2.jpg",
            title: "Landify home page",
            category: "UI DESIGN",
            desc: "",
            link: "",
          },
        ],
      },
      {
        id: 2,
        title: "Graphic Design",
        desc: "Our designers will work with you to produce high-quality designs that are based on your unique requirements. Giving you the ability to speak to your target audience through empowering visual designs.",
        subServiceSubtitle:
          "LOGO DESIGN | BRAND GUIDELINES | PRINT DESIGN | DIGITAL DESIGN",
        subServiceMainTitle:
          "LOGO - Your logo is one of the first things people see when they come across your business.",
        subServiceMainDesc:
          "It is important that your logo portrays your business in the correct way, leaving a lasting impression and engaging your audience. The logo is only the start of developing a quality brand identity. As a graphic design agency, our team of creative experts will also design all supporting graphics that are required such as stationery, brochures and websites.",
        subServiceFirstSectionTitle: "Print Design.",
        subServiceFirstSectionImg: "../images/about/graphicdesign.jpg",
        subServiceFirstSectionLinkUrl: "/contact",
        subServiceFirstSectionDesc:
          "We have a bank of professional printers on hand who can guarantee a high quality printed material that you will be proud of. This includes lithographic printing for branded stationery and brochures, as well as digital printing for short-run marketing promotions. We combine the skills of a creative graphic design agency with outstanding print quality to deliver a superb end result.",
        subServiceFirstSectionLinkTitle: "Get Your Designs",
        subServiceHowWeWorkTitle: "BILLBOARDS & FLYERS",
        subServiceHowWeWorkDesc:
          "Working alongside Salisbury City Council, the graphic design team at  Talent Individuals created a billboard advertisement to promote the annual Salisbury Carnival. Using a colourful festival style theme to suggest a fun, family vibe, the advert encouraged people of all ages to line the streets of Salisbury for the illuminated evening procession. This theme certainly helped the graphic stand out and catch the eye of passers-by. Having been Salisbury City Council's chosen graphic design agency for over 5 years,  Talent Individuals were delighted to collaborate with them on a poster and flyer design for their ‘environmental enforcement’ campaign. Displayed throughout the city with a strong message and vibrant, eye-catching colours, the campaign aimed to discourage the dropping of litter on the streets of Salisbury.",
        otherServiceLinkTitle: "GET IN TOUCH",
        otherServiceLinkUrl: "/contact",
        otherServiceImg: "../images/about/graphic-2.jpg",
        otherServiceTitle:
          "An experienced creative team offering graphic design services for businesses to enhance their brand messages.",
        otherServiceDesc:
          "Whether you need a rebrand or simply want to produce some quality promotional material, we are a skilled graphic design agency with the in-house expertise to create designs made to impress. Our graphic designers will work with you to produce high-quality designs based on your unique requirements. Providing you with the ability to speak to your target audience through empowering visual designs.",
        subServicePortfolio: [
          {
            id: 1,
            img: "../images/portfolio/graphic-1-1.jpg",
            secondimg: "../images/portfolio/graphic-1-2.jpg",
            title: "Soaked Body Co.",
            category: "Graphic Design",
            desc: "Create cigar style logo for body products company Soaked Body Co.",
            link: "",
            url: false,
          },
          {
            id: 2,
            img: "../images/portfolio/graphic-2.jpg",
            secondimg: "../images/portfolio/graphic2-1.jpg",
            title: "Multi-Use Logo Designs",
            category: "Graphic design",
            desc: "",
            link: "",
          },
        ],
      },
      {
        id: 3,
        title: "Video Production",
        desc: "We create compelling videos that engage your audience and drive growth for your business. Achieve more with your content by harnessing the power of video to create more targeted and impactful messaging.",
        subServiceSubtitle:
          "Create compelling videos that engage your audience and drive growth for your business.",
        subServiceMainTitle:
          "Achieve more with your content by harnessing the power of video to create more targeted and impactful messaging.",
        subServiceMainDesc:
          "It's nothing new, but what has changed is how important video has become to digital marketing. Video drives traffic, retains visitors and converts, making it essential for businesses' marketing, sales and growth. Whether you’re looking for a short promotional video, a business or corporate video, an animated explainer or educational video or even a large-scale production, our team will guide you from concept to completion.We offer a full video production service from an experienced team who have shot TV ads, music videos, corporate and educational videos.",
        subServiceFirstSectionTitle: "From concept to conversions",
        subServiceFirstSectionImg: "../images/about/videop.jpg",
        subServiceFirstSectionLinkUrl: "/contact",
        subServiceFirstSectionDesc:
          "Our video services start with you. We’ll work with you to understand your needs, goals and how to best convey your message. Writing, refining, tweaking until each element is ready.  Talent Individuals will support you throughout the project and can do as little or as much as you need. Projects can vary from designing a title or graphic sequence for a short series of one off 10 second to one minute videos, or if you need multiple videos to support an entire marketing campaign, we can do that too.",
        subServiceFirstSectionLinkTitle: "Get In Touch",
        subServiceHowWeWorkTitle: "We are a creative video production agency",
        subServiceHowWeWorkDesc:
          "Our video production starts when you're ready. We’ll sketch out storyboards and a preproduction schedule which clearly defines your promotional video or corporate video strategy. We’ll give as much help as you need with script writing and preparation.The tools at your disposal include voice overs, creating video animation, filming interviews with key influencers, personnel and clients and we will be there at every step. Your project could include any variation or combination of many elements. We realise the importance of making sure your video production is in keeping with your brand guidelines. Whatever the Project: Explainer videos,Guides and information,Events and Conferences,Charity and donations,Science and research,Your story,Education",
        otherServiceLinkTitle: " Get In Touch",
        otherServiceImg: "../images/about/video-2.jpg",
        otherServiceLinkUrl: "/contact",
        otherServiceTitle: "Our Process",
        otherServiceDesc:
          "Planning: Sketching out ideas and writing a treatment are the first steps.Who is your target audience? What messages do you need to convey? As audiences are identified and essential touch points become clear so too do the best visual language tools to engage with.A brief will become a script, and a script can turn into a storyboard, which translates into the final piece. | Shooting: 4k cameras and lenses? Check. Gimbals and sliders? Check. We want your footage to stand out. This could include anything from interviews with key influencers, personnel and clients, time-lapse of events and places, to drone footage of your building, or slow motion. We’ll work on a sensible shooting schedule which is respectful and works around your business. Editing: Video editing is about realising your vision. We’ll bring the footage into postproduction, and one of our video editors will fashion a well edited piece which dovetails simple or complex graphical elements with music, a voice over and all your recorded footage, taking all the acquired elements into the mix. As the video begins to take shape, you’ll decide where and when it is seen. Distribution: In a digital age, your footage can travel half way around the world in a millisecond, but for those that still need pressed copies, we can organise as many copies of your programme as you need. Design and labelling will reflect your design and brand. We can also help plan your video placement and promotion, and how to reach as many people as possible.",
        subServicePortfolio: [],
        contactWhenNoPort: "conact us",
      },
      {
        id: 4,
        title: "UX Design",
        desc: "Put the user at the heart of your web design, by delivering a user experience that will enhance satisfaction and interaction with your website. We produce designs that are tested for usability and accessibility.",
        subServiceSubtitle:
          "We help increase conversions, engagement and customer satisfaction.",
        subServiceMainTitle: "Improve online experiences with UX Design",
        subServiceMainDesc:
          "Enhancing the customer journey is crucial to making a success of any website. We are a user experience design agency with the know-how to keep users engaged, and crucially understand how to create profitable customer experiences. Our experienced UX design team utilise research and user testing to make informed design decisions, which results in websites that make it easy for users to accomplish their desired tasks.",
        subServiceFirstSectionTitle:
          "Do you want to make your website more effective and more successful?",
        subServiceFirstSectionImg: "../images/about/ux-1.jpg",
        subServiceFirstSectionLinkUrl: "/contact",
        subServiceFirstSectionDesc:
          " Talent Individuals can help companies whose websites are failing to perform. We can blend a mixture of analytical and research techniques with user focused-design to create a positive browsing experience for your customer. One where the visitor is more likely to engage, convert and return.",
        subServiceFirstSectionLinkTitle: "Design Service",
        subServiceHowWeWorkTitle:
          "What are the benefits of improving user experience?",
        subServiceHowWeWorkDesc:
          "We offer several UX services - all designed to measure your existing success, understand your user needs and provide the right recommendations to improve your online offering. By designing your website around your target audience, they will be more likely to find what they are looking for and spend more time on your site. We can help by: Understanding the needs of your users so that you can create a design that is more likely to succeed,Removing customer pain-points to create a more enjoyable and satisfying customer-experience,Helping you improve the user journey elsewhere in the sales funnel",

        otherServiceImg: "../images/about/ux-2.jpg",
        otherServiceTitle: "Start your UX project today",
        otherServiceLinkUrl: "/contact",
        otherServiceDesc:
          "Whether it’s by stakeholder feedback, focus groups, wireframe prototypes or design mockups, we'll work with you to determine the best way to plan and deliver your vision.",
        otherServiceLinkTitle: "SPEAK TO AN EXPERT",
        subServicePortfolio: [
          {
            id: 1,
            img: "../images/portfolio/uidesign2-1.jpg",
            secondimg: "../images/portfolio/uidesign2-2.jpg",
            title: "Fashion Photographer Mobile",
            category: "UI DESIGN",
            desc: "",
            link: "",
          },
          {
            id: 2,
            img: "../images/portfolio/uidesign-1-1.jpg",
            secondimg: "../images/portfolio/uidesign1-2.png",
            title: "Crypto Portfolio Mobile App - Landing Page",
            category: "UI DESIGN",
            desc: "",
            link: "",
          },
        ],
      },
      {
        id: 5,
        title: "Responsive",
        desc: "Mobile phones are the most popular devices to use and desktops come in at a close second, so you’ll want a site that’s usable and scalable for the various screen sizes and browsers that are available.",
        subServiceSubtitle:
          "A mobile first world means that every new website must be responsive.",
        subServiceMainTitle:
          "It almost goes without saying that a modern website needs to be responsive. If you want your business to have a digital presence then you need to make sure that users can get the full experience on mobile, tablet and desktop.",
        subServiceMainDesc:
          "Mobile phones are the most popular devices to use and desktops come in at a close second, so you’ll want a site that’s usable and scalable for the various screen sizes and browsers that are available. Your users are your #1 priority, so it’s essential that you make sure that they’ve got a consistent experience no matter what device they use. That’s what we do. We design sites with the user in mind. And as mobile-friendly sites are a must-have, we plan how your site will look on a mobile from the start. Our designers know their stuff, they’ll build your website to be responsive and test it to make sure that it works. We’ve designed hundreds of responsive websites and we’ll build a high-quality, professional site that makes your business look great.",
        subServiceFirstSectionTitle: "What is responsive web design?",
        subServiceFirstSectionImg: "../images/about/img-34.png",
        subServiceFirstSectionLinkUrl: "/contact",
        subServiceFirstSectionDesc:
          "Responsive design uses fluid grids and percentages, instead of absolute pixels or points, so that your website can respond and adapt to the screen size of the device being used. While it does take a bit more time to build a responsive website, it will save you money in the long run because you'll only have one website to update, develop, and optimise for search, keeping future development and digital marketing costs down.",
        subServiceFirstSectionLinkTitle: "Get In Touch",
        subServiceHowWeWorkTitle: "It’s all about user experience",
        subServiceHowWeWorkDesc:
          "Your users need to have the best experience whenever they interact with your website. The site should feature the same content but scaled and adjusted depending on the device being used. And most importantly, it needs to be easy to use and navigate to get users to perform the actions that you want. We plan ahead and think about your target audience and how they’ll engage with your site. And, during the design stage, we’ll show you wireframes and full-colour mock-ups for desktop, tablet and mobile, so you know exactly how it’ll look.",
        otherServiceLinkTitle: "Talk To an Expert",
        otherServiceImg: "../images/about/process-3.jpg",
        otherServiceTitle: "Content Marketing, Blogging & Social Media",
        otherServiceLinkUrl: "/contact",
        otherServiceDesc:
          "Make sure that all the great content that you’re producing gets seen. If you’re actively creating content on your website and sharing it on social media platforms, then chances are people will be viewing it on their mobile. You're more likely to get views and shares if you're sending users to a responsive website that is quick and easy to use.",
        subServicePortfolio: [
          {
            id: 1,
            img: "../images/portfolio/webdev-1-1.jpg",
            secondimg: "../images/portfolio/webdev-1-2.jpg",
            title: "Skyyonliquor",
            category: "Website Development",
            desc: "Skyyon is a whole sale liquor company, providing you with the best quality liquor, we have in stock an amazing range of the world’s tastiest and finest selection of liquor for every occasion. Designed and Coded by Us.",
            link: "",
            url: "www.skyyonliquor.com",
          },
          {
            id: 2,
            img: "../images/portfolio/webdev-2-1.jpg",
            secondimg: "../images/portfolio/webdev-2-2.jpg",
            title: "Psychic Monica",
            category: "Website Development",
            desc: "Psychic Monica performs palm readings, angel readings, crystal and meditation healings, tarot card readings, psychic life readings, chakra readings, crystal readings, astrology, numerology, and is a spiritual life coach. She also provides her services for parties, social gatherings and events. Monica's daughter is a 5th generation psychic. Her grandmother was her inspiration as she was practicing prior to Monica being born.",
            link: "",
            url: "https://psychicmonica.netlify.app/",
          },
        ],
      },
      // {
      //   id: 6,
      //   title: "Open Source CMS",
      //   desc: "We work with open source systems on a wide variety of web projects. These platforms, such as Joomla, Drupal, Typo3 and WordPress bring a range of commercial and practical benefits for your business website.",
      //   subServiceSubtitle:
      //     "We've been capturing and engaging users with stunning websites for over fifteen years",
      //   subServiceMainTitle:
      //     "Take your users on a journey by giving them the best experience on your website.",
      //   subServiceMainDesc:
      //     "Captivate users with awesome visuals and keep them immersed in your site by making it easy for them to find exactly what they want. The look and feel of a website will inspire, but user-friendliness is essential to achieving success. As a UK based Web Design Agency, we firmly believe that balancing these two aspects of design will result in the greatest user experience.Whether you want it to convert leads, make sales or drive traffic, your website will be designed by our expert web designers to achieve your goals.",
      //   subServiceFirstSectionTitle: "Designing a website for the end user",
      //   subServiceFirstSectionImg: "../images/about/img-34.png",
      //   subServiceFirstSectionDesc:
      //     "A website should be engaging, easy to navigate and focused on your target audience. The user journey dictates whether you convert a prospect into a profitable customer. Each decision you make about the design will affect how a user interacts with the page. You’ve got to include the right information so that users can make informed decisions and your calls to action should drive them toward a key goal. Our webpage designers can create a website that puts your users first. We’ll guide you on the best features and create user-focused designs with smooth navigation to make sure your website converts.",
      //   subServiceFirstSectionLinkTitle: "UX Design",
      //   subServiceHowWeWorkTitle: "How we work with you",
      //   subServiceHowWeWorkDesc:
      //     "With every mock-up, prototype and design concept, we’ll get your feedback so that you stay at the heart of the design process. We find that collaboration between you and our web design and development team is the most effective way to drive success. So, you’ll be able to chat to the team as much you need to fine tune those designs and complete your website.",
      //   otherServiceLinkTitle: "RESPONSIVE WEB DESIGN",
      //   otherServiceImg: "../images/about/process-3.jpg",
      //   otherServiceTitle: "Mobile-first optimised websites",
      //   otherServiceDesc:
      //     "Over 1/3 of UK search traffic comes exclusively from mobile devices, and with Google moving toward a mobile-first index, it goes without saying that your website must be mobile responsive, and our web design service offers that and more. It’s essential that your users have a consistent experience with your website no matter what device they use. As a web design agency, we carefully consider how designs look and how easy and intuitive they are to navigate on the myriad of devices available.",
      //   subServicePortfolio: [
      //     {
      //       id: 1,
      //       title: "samplw",
      //       img: "../images/portfolio/1.jpg",
      //       website: "https://www.blindspotdronesolutions.com/",
      //       desc: "Web Redesign For Blindspotdrones",
      //     },
      //     {
      //       id: 2,
      //       title: "Skyyonliqour",
      //       img: "../images/portfolio/2.jpg",
      //       website: "https://www.skyyonliqour.com/",
      //       desc: "Web Design For Skyyonliqour",
      //     },
      //   ],
      // },
    ],
    // servicePortfolio: [
    //   {
    //     id: 1,
    //     title: "Blindspotdrones",
    //     img: "../images/portfolio/1.jpg",
    //     website: "https://www.blindspotdronesolutions.com/",
    //     desc: "Web Redesign For Blindspotdrones",
    //   },
    //   {
    //     id: 2,
    //     title: "Skyyonliqour",
    //     img: "../images/portfolio/2.jpg",
    //     website: "https://www.skyyonliqour.com/",
    //     desc: "Web Design For Skyyonliqour",
    //   },
    //   {
    //     id: 2,
    //     title: "Skyyonliqour",
    //     img: "../images/portfolio/2.jpg",
    //     website: "https://www.skyyonliqour.com/",
    //     desc: "Web Design For Skyyonliqour",
    //   },
    // ],
  },
  {
    id: 2,
    serviceImg: "images/service/service-3.jpg",
    title: "Development",
    serviceDetailDescTitle:
      "Experienced developers who can build and support websites, including CMS and eCommerce sites, as well as mobile apps.",
    serviceDetailDescDesc:
      "We develop functional websites and apps that are optimised for performance and usability. With over a decade of experience in the industry, providing businesses with exceptional products, our developers can build anything you require.",
    serviceDesc:
      "We use a wide range of tools to create websites, web portals and applications that are easy to use and maintain.",
    subServiceList: [
      {
        id: 1,
        title: "Web Development",
        desc: "talent individuals is a web development agency with the in-house expertise to meet a diverse range of requirements, from standard CMS, for straight forward sites, to custom development of complex web portals and database applications.",
        subServiceSubtitle:
          "You know what makes your business great and we’ll help you show it.",
        subServiceMainTitle: "Built for Experience",
        subServiceMainDesc:
          "We’ve got a dedicated team of web developers ready to work with you to make your business accessible through an engaging and functional website. We build sites using the very best open source platforms keeping your business secure and at the cutting-edge of technology.",
        subServiceFirstSectionTitle:
          "Why Choose Us As Your Web Development Agency?",
        subServiceFirstSectionImg: "../images/about/webdev-2.jpg",
        subServiceFirstSectionLinkUrl: "/contact",
        subServiceFirstSectionDesc:
          "Your site brings people closer to you and we’ll help you shape their experience, utilising the skills of our in house experts as we are a specialist web development company. We’ve built 1000s of websites using a range of tools. From simple CMS for straight forward sites, to custom built apps and web portals. We’ll deliver a high quality site that’s easy to use and manage and we’ll train and support you to use it to its full potential.",
        subServiceFirstSectionLinkTitle: "Work With Us",
        subServiceHowWeWorkTitle: "Experienced Web Development Team",
        subServiceHowWeWorkDesc:
          "No matter your requirements, our experienced web development team will get to grips with your project and deliver results. Custom Built - Need a site from scratch? Working with you, our in-house team will develop high quality bespoke sites and applications that match the individual needs of your business. Communication - You need to know that we're building a product with you in mind, which is why we’ll keep you in the loop at every stage of the development process. Secure - Online security is paramount in our development and we know it’s a priority for your business. The sites we create don't compromise data for design.",
        otherServiceLinkTitle: "Get Your Website Now!!",
        otherServiceImg: "../images/about/webdev-22.jpg",
        otherServiceTitle: "Our Web Development Process",
        otherServiceLinkUrl: "/contact",
        otherServiceDesc:
          "The web development team deliver an array of web projects and create simple and easy to use websites that drive more business. We put steps in place to make sure you end up with a website that helps you reach your goals. Our process involves the following steps - Gather Insights, Create the Specification, Design, Development, Testing, Deployment",
        subServicePortfolio: [
          {
            id: 1,
            img: "../images/portfolio/webdev-1-1.jpg",
            secondimg: "../images/portfolio/webdev-1-2.jpg",
            title: "Skyyonliquor",
            category: "Website Development",
            desc: "Skyyon is a whole sale liquor company, providing you with the best quality liquor, we have in stock an amazing range of the world’s tastiest and finest selection of liquor for every occasion. Designed and Coded by Us.",
            link: "",
            url: "www.skyyonliquor.com",
          },
          {
            id: 2,
            img: "../images/portfolio/webdev-2-1.jpg",
            secondimg: "../images/portfolio/webdev-2-2.jpg",
            title: "Psychic Monica",
            category: "Website Development",
            desc: "",
            link: "",
            url: "https://psychicmonica.netlify.app/",
          },
        ],
      },
      {
        id: 2,
        title: "E Commerce",
        desc: "We offer a full end-to-end eCommerce website service, designed to convert visits in to sales. Our websites meet the high demands of online shoppers and positively represent your brand and company identity.",
        subServiceSubtitle:
          "Generate more sales on your website with user focused design and functionality.",
        subServiceMainTitle:
          "Create an experience for users that convert visits into sales.",
        subServiceMainDesc:
          "We create online shops that don’t just look amazing, but are built with user experience at the heart of the development. The site should be extremely easy to use, fully functional and secure.Thanks to a dedicated team of designers, developers and marketers, we consider every part of the user’s journey, from when a user lands on the site, to when they make a purchase, and how we can keep them coming back again and again. Visitors to your site will always expect to have an excellent online shopping experience and we are determined to make sure that happens.",
        subServiceFirstSectionTitle: "We are experienced ecommerce developers",
        subServiceFirstSectionImg: "../images/about/ecom-1.jpg",
        subServiceFirstSectionDesc:
          "We’ve got an experienced team that understands what it takes to build user-friendly websites that are functional, fast and SEO-ready. How your website is built can make the difference between a user who converts and keeps returning and one that leaves and disregards your brand in the future. A good website should be built from the ground up with efficiency in mind and careful consideration of the features and functionality used. You want to make sure that your website loads quickly on any device, with any connection, and this all comes down to the technical specifications of your website’s development. We’ve built numerous sites with varying functionality requirements so we understand what it takes to make yours successful. Tell us about your project and we’ll show you how it can happen.",
        subServiceFirstSectionLinkTitle: "Our Works",
        subServiceHowWeWorkTitle: "Supporting you beyond the project",
        subServiceFirstSectionLinkUrl: "/portfolio",
        subServiceHowWeWorkDesc:
          "There’s always more that can be done post-launch and we have a dedicated team of experts who can help you make the most of your new website. Keep your website up-to-date with the help of our support team who can provide updates, patches and upgrades should you need them. You can take full advantage of our digital marketing team who will help you generate more traffic with ongoing search engine optimisation, pay-per-click or content marketing. Or, work with our marketers and designers to make tweaks and adjustments to the site to improve conversion rate. We want to see you get the most out of your website so we’ll always be on hand to provide you with the assistance of our specialist teams. If you’ve got an existing website that you need development support with than we’re happy to help too. Whether it’s a one-off bit of support work, like an upgrade or bug fix, or if you’d like a dedicated maintenance agreement where we support you on a monthly basis.",
        otherServiceLinkTitle: "Talk To An Exert",
        otherServiceImg: "../images/about/ecom-2.jpg",
        otherServiceTitle: "Your ecommerce platform of choice",
        otherServiceLinkUrl: "/contact",
        otherServiceDesc:
          "We design and develop ecommerce websites using all major platforms, including WooCommerce, Magento 2 and Shopify. The right platform for you really depends on your business and there’s no one-size fits all solution. When you contact us, we’ll get to know you and the level of functionality you need, we’ll then make recommendations on the best CMS for you.",
        subServicePortfolio: [
          {
            id: 1,
            img: "../images/portfolio/ecom-1.jpg",
            secondimg: "../images/portfolio/ecom-2.png",
            title: "Style and Soul Cambodia",
            category: "E-Commerce",
            desc: "",
            link: "",
            url: "https://styleandsoul-cambodia.com/",
          },
          {
            id: 2,
            img: "../images/portfolio/webdev-2-1.jpg",
            secondimg: "../images/portfolio/webdev-2-2.jpg",
            title: "Psychic Monica",
            category: "E-Commerce",
            desc: "Skyyon is a whole sale liquor company, providing you with the best quality liquor, we have in stock an amazing range of the world’s tastiest and finest selection of liquor for every occasion. Designed and Coded by Us.",
            link: "",
            url: "https://psychicmonica.netlify.app/",
          },
        ],
      },
      {
        id: 3,
        title: "Mobile Application",
        desc: "We'll help your business retain a competitive edge online with the development of a mobile app. With smartphone traffic on the increase we can help you embrace this platform in the representation of your brand.",
        subServiceSubtitle:
          "An award winning digital company with experienced mobile app developers",
        subServiceMainTitle:
          "Bring your mobile app to life with engaging design and enhanced functionality to deliver an exceptional experience.",
        subServiceMainDesc:
          "Harness unprecedented visibility by placing your brand at the centre of a user’s digital world. A successful mobile application puts you at the user’s fingertips, on the one device they use more than any other. Whether it’s for consumers, companies or your staff, mobile apps exist for convenience. You want to make their experience effortless, with easy UX, simple functionality, smooth integration and fast loading speeds, so that your application works seamlessly. That’s where we come in. We have the design, development and technical expertise to build your app and ensure it fulfils its purpose.  Talent Individuals has a dedicated team of mobile app developers who design and build iOS and Android apps to meet your precise specifications. Our developers use Java, Objective-C, Swift, React Native and HTML5 coding languages to build apps for iOS, Android and cross platform.",
        subServiceFirstSectionTitle: "Built for your users",
        subServiceFirstSectionImg: "../images/about/mobiledev-1.jpg",

        subServiceFirstSectionDesc:
          "Our company has experienced designers and developers who work with you to understand your app's purpose and your target audience. Utilising our knowledge of the latest technologies and research into your users, we'll advise you on the designs and functionality to best deliver your user journeys. It also ensures that as the project progresses we always have UX at the forefront of any design changes.",
        subServiceFirstSectionLinkTitle: "Get In Touch",
        subServiceHowWeWorkTitle: "OUR PROCESS_",
        subServiceFirstSectionLinkUrl: "/contact",
        subServiceHowWeWorkDesc:
          "Understanding Your Project, Conduct extensive research around your product, Design & Wireframing, Having established the platform architecture and look and feel of the mobile app, we’ll move on to the full build.Before we sign-off a project we always have the app tested by our QA team. This involves unit, integration, acceptance, regression, performance and exploratory type testing conducted by automated and manual tools. Efficient testing ensures that we are able to deliver a quality end product to our client, further reducing risk and ensuring a positive user experience.",
        otherServiceLinkTitle: "  Get in Touch",
        otherServiceImg: "../images/about/mobiledev-2.jpg",
        otherServiceTitle: "COMMITTED TO QUALITY_",
        otherServiceLinkUrl: "/contact",
        otherServiceDesc:
          "Understanding your app so that we can provide a design and development solution tailored to you. We strongly believe that collaboration is key to success. We continually work with you and your users to refine your mobile app so that it achieves its goals. You’ll be involved in prototyping the design, not only giving you an insight into the finished product but also helping to steer its final development. Our team works to the highest quality standards. You receive the end product that you want and assurance in knowing that your product is backed by a fully accredited QMS.",
        subServicePortfolio: [
          {
            id: 1,
            img: "../images/portfolio/mobile-1.jpg",
            secondimg: "../images/portfolio/mobile-2.jpg",
            title: "Coworks App",
            category: "Mobile Application",
            desc: "Coworking spaces are the future of workplace. We built a smart and engaging platform for Owners and Managers of these spaces to efficiently and intuitively manage their members by using automated billing, room booking management, event rental components, and native member-focused mobile applications. As coworking members ourselves, we understood the pain points that members experience and were able to cultivate an experience that solves the pain points of running and operating as well as working at a coworking space.",
            link: "",
          },
        ],
      },
      {
        id: 4,
        title: "WordPress",
        desc: "WordPress is one of the most popular CMS, it’s extremely easy to use and has an extensive range of plugins. This open source CMS is free to install, upgrade and easily modified for design and functionality.",
        subServiceSubtitle:
          "We have an in-house team of expert WordPress developers and web designers.",
        subServiceMainTitle: "WordPress Development, Web Design and Support",
        subServiceFirstSectionLinkUrl: "/contact",
        subServiceMainDesc:
          "WordPress is one of the most popular and powerful CMS (Content Management System) due to its dependable, robust and open-source nature. Our expert WordPress developers know how best to design and develop your website using industry best-practice. We develop bespoke solutions that improve website speed and SEO scoring. We've implemented WordPress websites for NHS providers and local businesses using the WooCommerce extension. Other clients have also taken advantage of WordPress' ease of use, longevity, extensive support network and scalability to support extended functionality.We build sites with intuitive and easy to adapt interfaces so that once our work is complete you will be able to effortlessly manage your content. We are also able to provide our clients with training should this be required.",
        subServiceFirstSectionTitle: "Why WordPress?",
        subServiceFirstSectionImg: "../images/about/wordpress-1.jpg",
        subServiceFirstSectionDesc:
          "WordPress currently houses over seventy-five million websites worldwide thanks to its flexible, customisable and user-centered interface, making it the most popular CMS on the web today. Our WordPress web designers and developers have the skills and knowledge to use this open source platform to design and build you an eye catching, functional and secure website.",
        subServiceFirstSectionLinkTitle: "Get Your WordPress Website",
        subServiceHowWeWorkTitle: "WordPress Plugins and Extensions",
        subServiceHowWeWorkDesc:
          "We have a wealth of experience seamlessly integrating existing plugins into WordPress websites and developing custom components when required. For example, our WordPress developers have experience in implementing a variety of plugin types to enhance your site, including: eCommerce, Calendar and Booking, Custom Multi-Page Forms, Custom Post Types, News and RSS Feeds, Social Feeds, Third Party Integrations, Data Reporting, SEO, CRM integration",
        otherServiceLinkTitle: "WORDPRESS HOSTING",
        otherServiceImg: "../images/about/wordpress-2.jpg",
        otherServiceTitle: "Harnessing the Power of WordPress",
        otherServiceLinkUrl: "/contact",
        otherServiceDesc:
          " Talent Individuals has a team of in-house WordPress developers and web designers who have worked extensively with this platform. We offer WordPress development, design, support, training, hosting and security consultancy in all versions of WordPress. We provide a WordPress hosting service and will place your business on our dedicated hosting environment, providing a rapid, professional and reliable service from the outset. For those requiring WordPress support, your website will receive routine daily backups to both site files and databases, so you can rest assured that in the event that anything goes wrong with your website, we will be able to restore it quickly and without fuss.We're committed to providing the optimum WordPress hosting environment for all our client's websites.",
        subServicePortfolio: [
          {
            id: 1,
            img: "../images/portfolio/wordpress1-1.jpg",
            secondimg: "../images/portfolio/wordpress1-2.png",
            title:
              "Eucerin|Beiersdorf – Lifechanging Power of Dermatological Skincare",
            category: "Wordpress",
            desc: "Glenmark Pharmaceuticals South Africa (Pty) Ltd was launched in December 2005. The company has been based in Midrand, Johannesburg since its inception. What started as a smaller organisation with only seven employees has now grown to be a productive, innovative company with more than 40 employees. Since October 2018, Glenmark South Africa, under new and experienced leadership, has seen numerous promising developments in its existing and future portfolio, both in the dermatology and respiratory areas. Expanding on the company’s specialty in the dermatology arena, Glenmark South Africa is pleased to announce that they are the first company to launch a generic Imiquimod Cream (5 %) in South Africa. This marks the 1st of a very strong dermatology pipeline to follow in the coming years.",
            link: "",
            url: "https://skincancerfoundation.nexstep-events.co.za/",
          },
          // {
          //   id: 2,
          //   img: "../images/portfolio/uidesign-1-1.jpg",
          //   secondimg: "../images/portfolio/uidesign1-2.png",
          //   title: "Crypto Portfolio Mobile App - Landing Page",
          //   category: "UI DESIGN",
          //   desc: "",
          //   link: "",
          // },
        ],
      },
      // {
      //   id: 5,
      //   title: "Laravel",
      //   desc: "Our Laravel developers create expert Laravel products and offer specialist insight and knowledge to our clients. For organisations who are new to Laravel, we can offer development, consultancy and in-depth Laravel training to get you comfortable with your web application.",
      // },
      // {
      //   id: 6,
      //   title: "Joomla",
      //   desc: "We are one of the UK's leading Joomla specialists. talent individuals has an experienced in-house team offering development, design, support and training services for this award-winning content management system.",
      // },
    ],
  },
  {
    id: 3,
    serviceImg: "images/service/service-1.jpg",
    title: "Digital Marketing",
    serviceDetailDescTitle:
      "Our agency has a team of digital marketing specialists offering SEO, PPC, social media, content marketing and conversion rate optimisation support.",
    serviceDetailDescDesc:
      "We use these services to identify and engage our clients' target audiences in order to convert leads into sales. We utilise all available data to carefully consider and optimise websites and campaigns to ensure the best return on investment for our clients. We have proven that a combined holistic approach, which utilises all these digital marketing services substantially increases online traffic.",
    serviceDesc:
      "Professional UK agency offering honest, bespoke, multi-channel digital marketing services to a range of small and large private public companies.",
    subServiceList: [
      {
        id: 1,
        title: "SEO",
        desc: "Increase the organic traffic to your site from search engines.  Talent Individuals will deliver an all-encompassed, professional and bespoke SEO service that's right for your business and delivers measurable results.",
        subServiceSubtitle:
          "Maximise your site’s organic search potential with a data-driven SEO strategy that will deliver a return on investment.",
        subServiceMainTitle:
          "We’ll help you improve your site’s organic traffic and conversions with an ongoing SEO strategy.",
        subServiceMainDesc:
          "The top-ranking search results should be the most relevant websites for a user’s search query. Your strategy should focus on attracting users who will engage and convert. Not only should you target the right phrases, your landing pages need to be tailored to the user to provide the greatest user experience. We research and analyse your website, your target audience and the competition to inform our search engine optimisation campaign. We’ll then formulate a plan to adjust content, enhance design and improve site health, while carefully promoting your website online.",
        subServiceFirstSectionLinkUrl: "/contact",
        otherServiceLinkUrl: "/contact",
        subServiceFirstSectionTitle: "On-site SEO",
        subServiceFirstSectionImg: "../images/about/seo-1.jpg",
        subServiceFirstSectionDesc:
          "On-page search engine optimisation focuses on improving site speed, design and content to feature the information that users want. It’s more than just adding keywords to copy; you’ve got to think about how design and copy influence a user’s journey. A site that focuses its content on the end user will naturally incorporate relevant search terms to describe a product or service offering. Our SEO team utilise Google’s best practice, taking full advantage of the plethora of information that Google provides to help businesses like yours. Search is fluid and continually updating as Google enhances its algorithm, so we continually learn and keep an eye on thought leaders in the industry so that we’re aware of trends, new techniques and ideas. We take a user-centric approach to on-page SEO by analysing user intent and adjusting content accordingly. This ensures that while we follow the guidelines to get our webpage fundamentals right, we are constantly trying to create an enhanced experience that puts your site above the rest.",
        subServiceFirstSectionLinkTitle: "GET IN TOUCH",
        subServiceHowWeWorkTitle: "Outreaching and off-site SEO",
        subServiceHowWeWorkDesc:
          "It matters how your website appears across the internet. It’s important to maintain a consistent representation of your brand and ensure that your website is authoritative and trustworthy. Off-site SEO looks at outreaching, brand awareness, social visibility, third-party review sites and even your Google My Business and Bing Places listings. We'll monitor and manage these as part of your campaign to make sure your website makes the most of these external channels. We also work on managing your existing backlink profile and review low-quality websites that may be linking to you. Any backlink strategy should focus on naturally improving the quality and relevance of the domains linking to your site.",
        otherServiceLinkTitle: "Talk To An Expert",
        otherServiceImg: "../images/about/seo-2.jpg",
        otherServiceTitle: "Technical SEO",
        otherServiceDesc:
          "It’s essential to keep on top of your site’s health. You want to make it as simple as possible for users, and Google, to access web content. Technical SEO looks at indexation issues, broken links and crawl anomalies. We use Google products, like Search Console, as well as third-party tools to monitor and manage your website’s health and keep on top of it. *Statistics collected from backlinko.com/seo-stats Talent Individuals has a team of in-house WordPress developers and web designers who have worked extensively with this platform. We offer WordPress development, design, support, training, hosting and security consultancy in all versions of WordPress. We provide a WordPress hosting service and will place your business on our dedicated hosting environment, providing a rapid, professional and reliable service from the outset. For those requiring WordPress support, your website will receive routine daily backups to both site files and databases, so you can rest assured that in the event that anything goes wrong with your website, we will be able to restore it quickly and without fuss.We're committed to providing the optimum WordPress hosting environment for all our client's websites.",
        subServicePortfolio: [
          {
            id: 1,
            img: "../images/portfolio/seo-1.jpg",
            secondimg: "../images/portfolio/seo-2.jpg",
            title: "Sternberg law office",
            category: "SEO",
            desc: "This is a private law firm based in the USA. The company provides legal compensation services to the workers in the area. We are managing the SEO, Social Media and Online Marketing campaigns for the company. Within first two months, we managed to increase the website traffic through various on-page and off page techniques. Check out their latest Search Engine ranking below.",
            link: "",
            url: "www.sternberglawoffice.com",
          },
        ],
      },
      {
        id: 2,
        title: "PPC",
        desc: "We'll advertise your business online through Pay Per Click marketing, ensuring adverts are publicised in the right places, at the right times and to the right people, in order to effectively raise brand awareness and generate leads.",
        subServiceSubtitle:
          "We create effective PPC strategies to boost website visibility, increase leads, traffic and sales, and maximise your company’s online potential.",
        subServiceMainTitle:
          "Get your adverts shown in the right places, to target the right people, with Pay Per Click specialists.",
        subServiceMainDesc:
          "Pay per click is a form of paid online advertising that can supply you with instant traffic to your website, making it a perfect marketing tool for your business. Google Ads is the biggest platform in the marketplace and is mostly synonymous with PPC in the industry. Google's search results pages are extremely crowded and paid ads provide you with immediate visibility. Working with a PPC agency led by Pay Per Click consultants is the fastest way to obtain clicks, visits and leads for your website. As Pay Per Click specialists our services will ensure that your paid ads budget goes further, encompassing Google search, display, shopping and Bing.",
        subServiceFirstSectionTitle: "Display Ads and Remarketing",
        subServiceFirstSectionImg: "../images/about/ppc-1.jpg",
        subServiceFirstSectionLinkUrl: "/contact",
        otherServiceLinkUrl: "/contact",
        subServiceFirstSectionDesc:
          "Get your brand in front of your target audience while they’re browsing the web using Google’s display network. Display ads are a great way to build brand awareness and you can even target users who have previously visited your website with remarketing ads. And it’s also low cost. Our PPC specialists build display campaigns built around your target demographic and make sure ads only appear on relevant sites for your business. With the support of our graphic design team, we can also design stunning banners that make your brand stand out. They’ll use your brand guidelines and we’ll always get approval from you on the copy and design before putting the ads live. If you require a PPC audit or the expertise of a pay per click agency, we’d love to hear from you.",
        subServiceFirstSectionLinkTitle: "GET IN TOUCH",
        subServiceHowWeWorkTitle: "PPC Campaign Management Services",
        subServiceHowWeWorkDesc:
          "With an agreed monthly budget, our dedicated team can generate PPC results that will transform your business. Our PPC contracts are flexible, so we can start, stop, pause or amend your adverts at any time to suit your needs and budgets. Our pay per click service comprises of budget management, keyword bidding, advert creation, on-going campaign monitoring and reporting. Before the campaign begins, we'll discuss your objectives with you and recommend the campaigns and spend to achieve this. As part of our PPC management service, we'll also set up conversion tracking so that you can monitor your return on investment. Tracking conversions correctly is essential to measure results but also to help inform your decision-making when it comes to your marketing.",

        otherServiceImg: "../images/about/ppc-2.jpg",
        otherServiceTitle: "Building leads for the long term",
        otherServiceDesc:
          "Paid search can attract new customers quickly, and give your organic search efforts time to pay off and start to bring in long-term and more cost-effective website traffic. PPC audits conducted by a PPC consultant will review any existing campaign you have set up, and find areas for optimisation to yield you better results.",
        otherServiceLinkTitle: "GET IN TOUCH",
        subServicePortfolio: [
          {
            id: 1,
            img: "../images/portfolio/ppc-1.jpg",
            secondimg: "../images/portfolio/ppc-2.jpg",
            title: "COMMUNICATIONS SPECIALIST LTD",
            category: "Pay Per Services",
            desc: "The client approached Talent Individuals looking for support with their social media presence and to increase the number of leads from the UK market.  ",
            link: "",
            url: "https://www.comms-spec.com/",
          },
        ],
      },
      {
        id: 3,
        title: "Social Media",
        desc: "Our consultants can provide an integrated social media strategy for your business, to increase your online visibility, raise brand awareness, encourage engagement, drive website traffic, as well as increase leads and sales.",
        subServiceSubtitle:
          "We plan, implement, and deliver exciting and engaging social media marketing strategies for businesses.",
        subServiceMainTitle:
          "Grow your business through social media marketing",
        subServiceMainDesc:
          "Social media is the perfect tool to increase your online visibility, enabling you to share content and build relationships with new leads and existing customers. With over 4.5 billion users all over the world, social media is a key component of modern-day culture, and so for businesses it provides an exciting opportunity to grow by reinforcing your brand, services, and products.Not only is social media an incredible promotional tool that can help you reach people you otherwise might not be able to with traditional marketing and advertising practices, but maintaining an active social media presence can also help strengthen customer service",
        subServiceFirstSectionTitle:
          "Adding variety to your social media strategy",
        subServiceFirstSectionImg: "../images/about/SocialMedia-1.jpg",
        subServiceFirstSectionLinkUrl: "/contact",
        otherServiceLinkUrl: "/contact",
        subServiceFirstSectionDesc:
          "Social media marketing is most effective when integrated with your wider marketing efforts and, if managed properly, can become a key asset in achieving your business goals. Combined with SEO and content marketing, social media can project your voice online and boost the number of visitors to your website. Social media is also a primary way to build brand personality, surrounding your business with an engaging and communication-driven persona that helps build customer loyalty and familiarity. Our internal team can ensure your social media output is engaging and varied through using graphics, video, blog posts and much more to keep your digital touchpoints appealing. With skills in creating all types of long and short-form content, interactive content, and targeted content, our social media team can bolster your social media profiles with exciting, engaging, and data-driven touchpoints.",
        subServiceFirstSectionLinkTitle: "Get In Touch",
        subServiceHowWeWorkTitle: "Expert knowledge",
        subServiceHowWeWorkDesc:
          "Social media marketing increases engagement with your business and generates new opportunities for you. From product launches to sharing company news and running competitions, having a strong presence across multiple digital channels is vital for businesses.",
        otherServiceLinkTitle: "Get In Touch",
        otherServiceImg: "../images/about/SocialMedia-2.jpg",
        otherServiceTitle: "Paid social media advertising",
        otherServiceDesc:
          "Paid social media advertising can involve boosting your posts to accelerate their reach. We can discuss with you what kind of budget would work best based on your goals and which industry you operate in. Our substantial experience in paid ads across various social media channels will ensure your reach is maximised, your engagement levels grow and your likes and conversions increase. As a social media agency, we have vast experience in running social media for business and know the value that targeted social adverts, designed to reach a valuable demographic, can have in terms of acquiring new customers and increasing potential conversions.",
        subServicePortfolio: [
          {
            id: 1,
            img: "../images/portfolio/social-1.jpg",
            secondimg: "../images/portfolio/social-2.jpg",
            title: "Dream Doors",
            category: "Social Media",
            desc: "Blue Frontier have worked with Dream Doors for a number of years, providing an all-inclusive digital marketing package as well as web design and development services. ",
          },
        ],
      },
      {
        id: 4,
        title: "Content",
        desc: "We create and market quality, informative and well-structured content that engages website visitors, demonstrates authority in your industry, and helps generate a higher return on investment.",
        subServiceSubtitle:
          "Content is an excellent way to reach your target market, but in order to be a success it needs guidance and direction.",
        subServiceMainTitle:
          "How Our Content Services Can Help Your Business Grow",
        subServiceMainDesc:
          "We specialise in helping clients create content that drives engagement and lands new business. Content is not a selling tool, it is used to attract new users and increase awareness of your brand. Behind every successful piece of content is careful planning and a strong strategy, to ensure it meets the requirements of the user. Content is an essential tool in your wider marketing strategy whether it is required to get your social media soaring or to support your SEO. We are well versed in planning content and uncovering audience demand through keyword and competitor research. We create unique and compelling content, which can be shared via your marketing channels. Likewise, we are also able to assist in updating and enhancing existing content to enhance SEO potential. ",
        subServiceFirstSectionTitle: "Content Strategy",
        subServiceFirstSectionImg: "../images/about/Content-1.jpg",
        subServiceFirstSectionLinkUrl: "/contact",
        otherServiceLinkUrl: "/contact",
        subServiceFirstSectionDesc:
          "The success of content depends on the strategy behind it. Planning and creating cross channel content, in a variety of formats is what is required in order to get your brand heard. From whitepapers and guest posts to podcasts and video, the real value of content is formulating a structured strategy to reach new audiences and engage existing ones. We work closely with brands to support them in curating a successful strategy, while also taking stock of their content marketing efforts and adjusting the plan to align with business goals. We are able to provide you with a complete content marketing strategy from initial research right through to PR and ongoing content creation.",
        subServiceFirstSectionLinkTitle: "Speak to an Expert",
        subServiceHowWeWorkTitle: "Email Marketing",
        subServiceHowWeWorkDesc:
          " Talent Individuals provide strategic and effective email marketing services. We design and build templates, develop strategies and provide creative copy writing to ensure your emails drive new leads and land exciting opportunities for your business. Email is still a hugely popular and proven marketing channel with click through rates outperforming those of social media and affiliate marketing. Our team also ensure that any emailing efforts will fall within GDPR regulations. Lead magnet funnels also encourage engagement from users by building trust and interest over time. We help to strategise and implement lead magnet funnels to meet the required goals and adapt them for your business to boost brand awareness.",
        otherServiceLinkTitle: "We Can Help",
        otherServiceImg: "../images/about/Content-2.jpg",
        otherServiceTitle: "Copywriting",
        otherServiceDesc:
          "Our team of skilled copywriters have experience in delivering all kinds of content. From punchy copy for social media campaigns, to curated content for technical brochures we offer our clients a full spectrum of copywriting services. We write copy to increase click through rates for PPC campaigns. We write regular blog posts for a huge range of clients from varying sectors. We write website content for brand new websites to increase reach and build trust.",
        subServicePortfolio: [
          {
            id: 1,
            img: "../images/portfolio/uidesign-1-1.jpg",
            secondimg: "../images/portfolio/uidesign1-2.png",
            title: "Crypto Portfolio Mobile App - Landing Page",
            category: "UI DESIGN",
            desc: "",
            link: "",
          },
          {
            id: 2,
            img: "../images/portfolio/uidesign-1-1.jpg",
            secondimg: "../images/portfolio/uidesign1-2.png",
            title: "Crypto Portfolio Mobile App - Landing Page",
            category: "UI DESIGN",
            desc: "",
            link: "",
          },
        ],
      },
      // {
      //   id: 5,
      //   title: "CRO",
      //   desc: "Conversion Rate Optimisation is the process of improving your web pages to convert more website traffic into new customers. Carefully considered tweaks and changes can have a significant impact on conversions.",
      // },
      // {
      //   id: 6,
      //   title: "Competitive Audit",
      //   desc: "Before devising your digital marketing strategy our consultants will complete a full analysis of your online competitors, identifying where their strengths lie, how they market their business and which audiences they are targeting.",
      // },
    ],
    servicePortfolio: [
      {
        id: 1,
        title: "Blindspotdrones",
        img: "../images/portfolio/1.jpg",
        website: "https://www.blindspotdronesolutions.com/",
        desc: "Web Redesign For Blindspotdrones",
      },
      {
        id: 2,
        title: "Skyyonliqour",
        img: "../images/portfolio/2.jpg",
        website: "https://www.skyyonliqour.com/",
        desc: "Web Design For Skyyonliqour",
      },
    ],
  },
  {
    id: 4,
    serviceImg: "images/service/service-3.jpg",
    title: "Technical",
    serviceDetailDescTitle:
      "Take advantage of our knowledge and experience in technology to improve your IT systems.",
    serviceDetailDescDesc:
      "We offer a complete technical service to a range of businesses. Providing IT Support, Web and Email Hosting, Network Solutions, Cloud Computing, IT Procurement and Consultancy. Make your systems more secure and reliable and maintain business continuity by utilising our technical expertise.",
    serviceDesc:
      "Specialist IT engineers and analysts sharing their expertise with businesses to provide consultancy, support, hosting and maintenance to improve clients' technological capabilities.",
    subServiceList: [
      {
        id: 1,
        title: "IT Solutions",
        desc: "Looking for trustworthy IT solutions for your business? Our skilled and experienced technicians will provide your business with expert IT solutions, tailored to effectively and securely manage your technical problems.",
        subServiceSubtitle:
          "Providing you with a bespoke solution to your business' technical problems. We consult, deploy, manage and support your IT.",
        subServiceMainTitle:
          "We are a team of experienced IT consultants and engineers.",
        subServiceMainDesc:
          "Our technicians provide you with consultancy, deployment and management of IT solutions that are designed and built around the needs of your business.",

        subServiceFirstSectionTitle: "IT Procurement",
        subServiceFirstSectionImg: "../images/about/ITProcurement-1.jpg",
        subServiceFirstSectionLinkUrl: "/contact",
        otherServiceLinkUrl: "/contact",
        subServiceFirstSectionDesc:
          "We help you manage your IT procurement by working with you and stakeholders to understand your company’s needs and identify the best products. From specification, researching and demoing, to deployment, migration and any after-sales support, you can outsource it all to our consultants. We have a team of experts who understand the latest technology and how it can best be implemented for business, whether you’re a large or small organisation. We can handle it all so that you can focus on what matters most: your business.",
        subServiceFirstSectionLinkTitle: "Speak to an Expert",

        subServiceHowWeWorkTitle: "Network Solutions",
        subServiceHowWeWorkDesc:
          "Your network infrastructure is integral to the day-to-day operation of your business. Maintaining uptime, accessibility, speed and security is essential to keep everybody working and your business secure. We can support you with building and managing your network so that it’s robust and scalable, and a lot more cost effective.",
        otherServiceLinkTitle: "Get In Touch",
        otherServiceImg: "../images/about/ITProcurement-2.jpg",
        otherServiceTitle: "Business Continuity & Disaster Recovery",
        otherServiceDesc:
          "A business continuity and disaster recovery plan should never be overlooked – we never know when the next crisis is around the corner. Our consultants can help you plan ahead and implement any processes or solutions that are needed so that you can maintain uptime in an emergency.Whether that’s securing data in the event of damage to property or theft, or ensuring that everyone in the business can work from home. We’ll audit your business and devise a strategy to keep your business up and running.",
        subServicePortfolio: [],
      },
      {
        id: 2,
        title: "IT Support",
        desc: "Comprehensive IT support for a range of different needs. We provide regular maintenance and consultancy services to maximise uptime and reduce business disruption with a highly experienced in-house team of technicians.",
        subServiceSubtitle:
          "Business IT support company in Salisbury, Southampton and Bristol",
        subServiceMainTitle:
          "We are IT specialists providing technical solutions to businesses in the UK",
        subServiceMainDesc:
          "Located in Salisbury and Southampton,  Talent Individuals has a dedicated team of in-house IT experts with decades of experience working with local, regional and national businesses. We diagnose IT issues on a daily basis and provide routine software and hardware support. We also provide consultation on implementing new systems and contingency planning. Whatever your business needs, we can provide you with a bespoke solution to support your business.",

        subServiceFirstSectionTitle:
          "Take advantage of our IT support services",
        subServiceFirstSectionImg: "../images/about/ITSupport-1.jpg",
        subServiceFirstSectionLinkUrl: "/contact",
        otherServiceLinkUrl: "/contact",
        subServiceFirstSectionDesc:
          "Get any IT issue resolved quickly and keep your business moving. You need the specialist support at your fingertips to prevent disruption and we have engineers who support businesses every day to fix problems as soon as possible. Make sure your IT systems are stable and reliable with regular maintenance and monitoring. We’ll look after this for you to protect your business against threats and we'll deal with problems before they pose a risk. We work in a threatening cyber environment, with viruses and malware posing an everyday risk. These risks are entirely manageable through routine maintenance, patching and best practice. We’re experienced in identifying vulnerabilities and implementing improved security measures to stop these threats. Make the most of new technology to drive your business forward. We don’t just provide IT support, we help you evolve with it. We’re up-to-date with the latest technologies and can help improve business performance and efficiency through technology.",
        subServiceFirstSectionLinkTitle: "Speak to an Expert",

        subServiceHowWeWorkTitle: "Core IT Services",
        subServiceHowWeWorkDesc:
          "We provide an extensive range of IT support services to help support your business as you grow, including but not limited to the following fundamental core IT services. Remote IT Support, 24/7 IT Support, IT Relocation Services, Network Solutions, Virtualisation, Server Management",

        otherServiceLinkTitle: "Get In Touch",
        otherServiceImg: "../images/about/ITSupport-2.jpg",
        otherServiceTitle:
          "We can support you with some of the most popular technology",
        otherServiceDesc:
          "The team support businesses with some of the most popular technology on the marketplace. We can help you with: Microsoft - The leading software provider, our staff are fluent in all Microsoft products, offering support in Hyper-V, SQL, Server Migration and more. VMware - A popular infrastructure platform, VMware offers network virtualisation that lets you manage and connect apps across clouds and devices. Utilise our experience in VMware vSan, clusters and maintenance. Cisco - Use Cisco to manage network infrastructures, applications and tools. We’re experienced in Cisco switching, routing, firewalls and more.",
        subServicePortfolio: [],
      },
      {
        id: 3,
        title: "Cloud Service",
        desc: "Make it easier to manage the cost of your business' IT infrastructure by moving to the Cloud. You can simplify IT costs to a single monthly payment with our reliable and resilient cloud computing services.",
        subServiceSubtitle:
          "We can support your business’s IT infrastructure with scalable cloud solutions designed around your requirements.",
        subServiceMainTitle: "Our Cloud Services.",
        subServiceFirstSectionLinkUrl: "/contact",
        otherServiceLinkUrl: "/contact",
        subServiceMainDesc:
          " Talent Individuals provides a complete managed cloud service. Taking full advantage of the best products available on the market, we can design and build a bespoke cloud infrastructure for your business. Our services include: Cloud Computing, Cloud Consultancy, Cloud Support & maintenance, Microsoft Office 365 Business, AWS Services, Microsoft Azure Solutions, Hosted Windows Desktop, Online Data Backup, cloud-based data archiving, Disaster Recovery in the cloud,Private Cloud Hosting. We can help you migrate your IT infrastructure to the cloud and support you as you use it in day-to-day business. We have a dedicated infrastructure support team who are specialists in AWS, Azure and Office 365, who can create a solution for you.",

        subServiceFirstSectionTitle: "AWS Consultancy",
        subServiceFirstSectionImg: "../images/about/CloudService-1.jpg",
        subServiceFirstSectionDesc:
          "AWS is the largest cloud service provider with a plethora of products that can be used by businesses to support their service delivery. It’s secure, flexible and scalable and is a trusted provider of cloud based infrastructures.  Talent Individuals is an AWS partner, which means that we’re able to provide clients with holistic advice on the platform. It doesn’t matter what size your business is or what sector, we can create a solution for you.",
        subServiceFirstSectionLinkTitle: "Speak to an Expert",

        subServiceHowWeWorkTitle: "Private Cloud Hosting",
        subServiceHowWeWorkDesc:
          "Host your website, application or software on a dedicated hosting environment that’s secure, reliable, and gives you complete control. If you’re processing sensitive data and want to retain ownership and control, but want all the benefits of a cloud solution, we can help you build a private cloud infrastructure that’s dedicated to you and your business.",

        otherServiceLinkTitle: "Get In Touch",
        otherServiceImg: "../images/about/CloudService-2.jpg",
        otherServiceTitle: "What is cloud computing?",
        otherServiceDesc:
          "Access IT resources over the internet on-demand. Rather than maintaining your own physical servers or data centres, you can get all the computing power you need from a cloud provider.  Talent Individuals provides managed cloud solutions. We work with you to understand your IT requirements and identify the best cloud products for your needs. We’ll help migrate your existing infrastructure to the cloud and provide you with ongoing support to maximise its potential.",
        subServicePortfolio: [],
      },
      {
        id: 4,
        title: "Cyber Security",
        desc: "A full range of Cyber Security services to best position your business to identify, manage and mitigate information security risks.",
        subServiceSubtitle:
          "Boost your business’s cyber security posture by identifying, managing and further mitigating your security risks.",
        subServiceMainTitle: "IT & Cyber Security",
        subServiceFirstSectionLinkUrl: "/contact",
        otherServiceLinkUrl: "/contact",
        subServiceMainDesc:
          "We’ll help you improve and enhance your security posture by implementing a bespoke and proactive cyber security strategy. You can rely on our dedicated team to deliver first-rate cyber security support, and if you want to set your business apart from your competitors with CREST-approved cyber security services, our experienced Security team can help. We have been awarded a coveted CREST accreditation for both Vulnerability Assessment and Penetration Testing. Our dedicated security analysts and architects offer a multitude of cyber security services that cover the full scope of cyber security risk. Whether it be vulnerability assessments to ensure regulatory compliance or full stack penetration testing to satisfy board directives, the  Talent Individuals Security team is dedicated to providing you with an honest and exacting service that aims to exceed your expectations.",

        subServiceFirstSectionTitle: "Security as a Service",
        subServiceFirstSectionImg: "../images/about/CyberSecurity-1.jpg",
        subServiceFirstSectionDesc:
          "Security as a Service is a delivered model of outsourcing information security services. This can be provided either as a subscription-based service or a pay-as-you-go based service. SECaaS allows for scalability that is intrinsically linked with the business’s growth and expansion. This transfer of risk from the client to ourselves will assist in mitigating any information security gaps that they may have.",
        subServiceFirstSectionLinkTitle: "Speak to an Expert",

        subServiceHowWeWorkTitle: "Cyber Security Assessment",
        subServiceHowWeWorkDesc:
          "A cyber security risk assessment is an essential part of any organisation’s risk management strategy. In this day and age, the majority of companies rely on information technology and information systems to conduct business and this poses inherent risks. However, with a CREST-accredited and professional cyber security assessment, you can ensure that your business is in the best situation to better mitigate any potential threats and vulnerabilities. We identify vulnerabilities, improve security posture and put in place proactive defences to help your company meet regulatory requirements and maintain trust with your customers. We’ll work with you and your business’s goals to provide you with effective mitigation and protection, including all the necessary technical support.",
        otherServiceLinkTitle: "We Can Help",
        otherServiceImg: "../images/about/CyberSecurity-2.jpg",
        otherServiceTitle:
          "Web Application Penetration & Vulnerability Testing",
        otherServiceDesc:
          "Web applications are frequent cyber-attack points because their complex source coding and high-value rewards make them easy targets. Web application security is the process of protecting online sites and services against cyber threats, and are key to protecting important applications such as content management systems and data administration tools.",
        subServicePortfolio: [],
      },
      {
        id: 5,
        title: "Business Intelligence and Analytics",
        desc: "The simplification of BI and Analytic tools with provision for greater decision making capabilities has enabled a greater participation from the business community.",
        subServiceSubtitle:
          "The simplification of BI and Analytic tools with provision for greater decision making capabilities has enabled a greater participation from the business community. End users from business analysts to operational staff are empowered to make immediate intelligent decisions that increases resource efficiencies with a reduction in data specialists’ routine efforts towards more value-added activities.",

        subServiceMainTitle:
          "Making Data Smarter with Business Intelligence and Analytics",
        subServiceMainDesc:
          "Enterprises capitalizing on the value derived from big data through efficient analysis possess an obvious competitive edge - an ability to make wiser business decisions. Business intelligence (BI) solutions in data management for business decision-making help in understanding the current business metrics, in forecasting with ‘what-if’ scenario analysis, in planning and control. Businesses will find BI tools to be the solution they are seeking for transformation through internal resource efficiency and external opportunities.",

        subServiceFirstSectionTitle:
          "Benefits of BI and Analytics for your Business",
        subServiceFirstSectionImg: "../images/about/CyberSecurity-1.jpg",
        subServiceFirstSectionLinkUrl: "/contact",
        otherServiceLinkUrl: "/contact",
        subServiceFirstSectionDesc:
          "Data originating from multiple applications and platforms or in its native form exists within mostly self-contained siloed lakes, databases and warehouses in isolation without regard to the immense value that can be generated from integration. Modern BI tools facilitates in streamlining and integrating data into a holistic framework. The analytic latency of legacy BI systems attributed from the segmentation of roles specialization, processes and storage formulations has been replaced by online hybrid transactional analytical processing. The real-time analytics of high volumes of transactional data offers immediate decision making capabilities within a ‘live’ operational environment.",
        subServiceFirstSectionLinkTitle: "Speak to an Expert",

        subServiceHowWeWorkTitle: "cloud BI",
        subServiceHowWeWorkDesc:
          "Cloud-based business intelligence, or cloud BI, describes the process of transforming data into actionable insights either partially or fully within a cloud environment. Cloud BI gives organizations the information they need to make data-driven decisions without the cost or hassle of physical hardware.",

        otherServiceLinkTitle: "Get In Touch",
        otherServiceImg: "../images/about/CyberSecurity-2.jpg",
        otherServiceTitle: "PowerBI architecture",
        otherServiceDesc:
          "Power BI architecture is a service built on top of Azure. There are multiple data sources that Power BI can connect to. Power BI Desktop allows you to create reports and data visualizations on the dataset. Power BI gateway is connected to on premise data sources to get continuous data for reporting and analytics.",
        subServicePortfolio: [],
      },
      {
        id: 6,
        title: "cloud migration",
        desc: "Migrate critical business application workloads to the cloud to improve agility, operational resilience, workforce productivity and cost effectiveness.",
        subServiceSubtitle:
          "For more than a decade, enterprises have been relying on Talent Individuals cloud migration services to successfully migrate their critical business application workloads to the cloud across a variety of platforms.",

        subServiceMainTitle: "cloud migration",
        subServiceMainDesc:
          "Whether it’s AWS, Azure, GCP or other cloud vendors, Sumo Logic provides full-stack observability at scale with deep security insights for digital services across cloud platforms.",

        subServiceFirstSectionTitle: "Cloud-native architecture",
        subServiceFirstSectionImg: "../images/about/CyberSecurity-1.jpg",
        subServiceFirstSectionLinkUrl: "/contact",
        otherServiceLinkUrl: "/contact",
        subServiceFirstSectionDesc:
          "Better. Faster. Cheaper. That’s the promise of cloud-native. Talent Individuals offers a SaaS analytics platform to manage your cloud migration and fulfill that promise.",
        subServiceFirstSectionLinkTitle: "Get Yours Done",

        subServiceHowWeWorkTitle: "Service map & dashboards",
        subServiceHowWeWorkDesc:
          "Out-of-the-box service maps and cloud performance dashboards offer real-time insights into the health and dependencies of your microservice and integration architecture.",

        otherServiceLinkTitle: "Get In Touch",
        otherServiceImg: "../images/about/CyberSecurity-2.jpg",
        otherServiceTitle: "DevSecOps lifecycle",
        otherServiceDesc:
          "Automate and observe end-to-end application lifecycle processes across your supply chain, from development, to operations and security.",
        subServicePortfolio: [],
      },
      {
        id: 7,
        title: "ETL (extract, transform, load) ",
        desc: "most popular method of collecting data from multiple sources and loading it into a centralized data warehouse",
        subServiceSubtitle:
          "most popular method of collecting data from multiple sources and loading it into a centralized data warehouse",

        subServiceMainTitle: "ETL (extract, transform, load)",
        subServiceMainDesc:
          "the most popular method of collecting data from multiple sources and loading it into a centralized data warehouse. During the ETL process, information is first extracted from a source such as a database, file, or spreadsheet, then transformed to comply with the data warehouse’s standards, and finally loaded into the data warehouse",

        subServiceFirstSectionTitle: "Integrate.io",
        subServiceFirstSectionImg: "../images/about/CyberSecurity-1.jpg",
        subServiceFirstSectionDesc:
          "Scalability, security, and excellent customer support are a few more advantages of Integrate.io. For example, Integrate.io has a new feature called Field Level Encryption, which allows users to encrypt and decrypt data fields using their own encryption key. Integrate.io also makes sure to maintain regulatory compliance with laws like HIPAA, GDPR, and CCPA.",
        subServiceFirstSectionLinkTitle: "Speak To An Expert",
        subServiceFirstSectionLinkUrl: "/contact",
        otherServiceLinkUrl: "/contact",

        subServiceHowWeWorkTitle: "Talend",
        subServiceHowWeWorkDesc:
          "Talend Data Integration is an open-source ETL data integration solution. The Talend platform is compatible with data sources both on-premises and in the cloud, and includes hundreds of pre-built integrations. Talend has received an average rating of 4.0 out of 5 stars on G2, as well as the designation of “Leader” in Gartner's Magic Quadrant for Data Integration Tools report. Reviewer Jan L. says that Talend is a “great all-purpose tool for data integration” with “a clear and easy-to-understand interface.”",

        otherServiceLinkTitle: "Get In Touch",
        otherServiceImg: "../images/about/CyberSecurity-2.jpg",
        otherServiceTitle: "Data Warehousing",
        otherServiceDesc:
          "Data warehouse is a central repository of information that can be analysed to make more informed decisions. Data flows into a data warehouse from transactional systems, relational databases, and other sources, typically on a regular cadence.",
        subServicePortfolio: [],
      },
      // {
      //   id: 5,
      //   title: "Business Hosting",
      //   desc: "A full range of Cyber Security services to best position your business to identify, manage and mitigate information security risks.",
      // },
      // {
      //   id: 6,
      //   title: "Private Cloud Hosting",
      //   desc: "From bespoke IaaS and PaaS solutions to offering and supporting third party cloud-hosted products, we have experience and skill in all necessary technologies to deliver the services your business needs.",
      // },
    ],
    servicePortfolio: [],
  },
];
