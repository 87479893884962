import React from "react";
import { Link, useParams } from "react-router-dom";
import SubHeader from "../../pageComponents/subHeader";
import serviceListData from "../../siteData/serviceListData";
const EachServiceDetailPage = () => {
  const { servicetitle } = useParams();

  const serviceData = serviceListData.find(
    (service) => service.title === servicetitle
  );
  const title = servicetitle;
  return (
    <>
      <SubHeader title={title} />
      {/* START MAIN TITLE */}

      <section className="section case-study">
        <div className="container">
          <div className="columns">
            <div className="columns">
              <div className="column lg-6">
                <div className="case-img">
                  <img
                    src={`../${serviceData.serviceImg}`}
                    alt=""
                    className="w-100"
                  />
                </div>
              </div>

              <div className="column lg-6 column md-8">
                <div className="case-content pl-4 mt-4 mt-lg-0">
                  <h4 className="mb-3">{serviceData.serviceDetailDescTitle}</h4>

                  <p>{serviceData.serviceDetailDescDesc}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* END MAIN TITLE */}

      <section className="section " style={{ padding: "0 0" }}>
        <div className="container">
          <div className="columns is-desktop is-justify-content-center">
            <div className="column is-10-desktop">
              <div className="heading has-text-centered">
                <h2>
                  Through our {serviceData.title} Services, we help
                  entreprenuers starts up and turn
                  <span className="text-color"> their ideas into</span> produtcs
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section services">
        <div className="container">
          <div className="columns is-multiline">
            {serviceData.subServiceList.map((each) => (
              <div className="column is-3-desktop is-6-tablet mb-5">
                <div className="team-block mb-5 mb-lg-0">
                  <h4 className="mt-4 mb-0">{each.title}</h4>
                  <p>{each.desc}</p>
                </div>
                <Link
                  to={`/subservicepage/${each.title}`}
                  className="btn btn-main"
                >
                  See More
                  <i className="fa fa-angle-right ml-2"></i>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default EachServiceDetailPage;
