export default [
  {
    id: 1,
    img: "images/portfolio/uidesign-1-1.jpg",
    secondimg: "../images/portfolio/uidesign1-2.png",
    title: "Crypto Portfolio Mobile App - Landing Page",
    category: "UI DESIGN",
    desc: "",
    link: "",
  },
  {
    id: 2,
    img: "../images/portfolio/webdesign1.jpg",
    secondimg: "../images/portfolio/webdesign-2.jpg",
    title: "Landify home page",
    category: "UI DESIGN",
    desc: "",
    link: "",
  },
  {
    id: 2,
    img: "images/portfolio/seo-1.jpg",
    secondimg: "../images/portfolio/seo-2.jpg",
    title: "Sternberg law office",
    category: "SEO",
    desc: "This is a private law firm based in the USA. The company provides legal compensation services to the workers in the area. We are managing the SEO, Social Media and Online Marketing campaigns for the company. Within first two months, we managed to increase the website traffic through various on-page and off page techniques. Check out their latest Search Engine ranking below.",
    link: "",
    url: "www.sternberglawoffice.com",
  },
  {
    id: 2,
    img: "../images/portfolio/graphic-2.jpg",
    secondimg: "../images/portfolio/graphic2-1.jpg",
    title: "Multi-Use Logo Designs",
    category: "Graphic design",
    desc: "",
    link: "",
  },
  {
    id: 4,
    img: "images/portfolio/graphic-1-1.jpg",
    secondimg: "../images/portfolio/graphic-1-2.jpg",
    title: "Soaked Body Co.",
    category: "Graphic design",
    desc: "Create cigar style logo for body products company Soaked Body Co.",
    link: "",
    url: false,
  },
  {
    id: 1,
    img: "../images/portfolio/uidesign2-1.jpg",
    secondimg: "../images/portfolio/uidesign2-2.jpg",
    title: "Fashion Photographer Mobile",
    category: "UI DESIGN",
    desc: "",
    link: "",
  },
  {
    id: 1,
    img: "../images/portfolio/webdev-1-1.jpg",
    secondimg: "../images/portfolio/webdev-1-2.jpg",
    title: "Skyyonliquor",
    category: "Website Development",
    desc: "Skyyon is a whole sale liquor company, providing you with the best quality liquor, we have in stock an amazing range of the world’s tastiest and finest selection of liquor for every occasion. Designed and Coded by Us.",
    link: "",
    url: "www.skyyonliquor.com",
  },
  {
    id: 1,
    img: "../images/portfolio/webdev-1-1.jpg",
    secondimg: "../images/portfolio/webdev-1-2.jpg",
    title: "Skyyonliquor",
    category: "E-Commerce",
    desc: "Skyyon is a whole sale liquor company, providing you with the best quality liquor, we have in stock an amazing range of the world’s tastiest and finest selection of liquor for every occasion. Designed and Coded by Us.",
    link: "",
    url: "www.skyyonliquor.com",
  },
  {
    id: 2,
    img: "../images/portfolio/webdev-2-1.jpg",
    secondimg: "../images/portfolio/webdev-2-2.jpg",
    title: "Psychic Monica",
    category: "Website Development",
    desc: "",
    link: "",
    url: "https://psychicmonica.netlify.app/",
  },
  {
    id: 1,
    img: "../images/portfolio/ecom-1.jpg",
    secondimg: "../images/portfolio/ecom-2.png",
    title: "Style and Soul Cambodia",
    category: "E-Commerce",
    desc: "",
    link: "",
    url: "https://styleandsoul-cambodia.com/",
  },
  {
    id: 1,
    img: "../images/portfolio/mobile-1.jpg",
    secondimg: "../images/portfolio/mobile-2.jpg",
    title: "Coworks App",
    category: "Mobile Application",
    desc: "Coworking spaces are the future of workplace. We built a smart and engaging platform for Owners and Managers of these spaces to efficiently and intuitively manage their members by using automated billing, room booking management, event rental components, and native member-focused mobile applications. As coworking members ourselves, we understood the pain points that members experience and were able to cultivate an experience that solves the pain points of running and operating as well as working at a coworking space.",
    link: "",
  },
  {
    id: 1,
    img: "../images/portfolio/wordpress1-1.jpg",
    secondimg: "../images/portfolio/wordpress1-2.png",
    title: "Eucerin|Beiersdorf – Lifechanging Power of Dermatological Skincare",
    category: "Wordpress",
    desc: "Glenmark Pharmaceuticals South Africa (Pty) Ltd was launched in December 2005. The company has been based in Midrand, Johannesburg since its inception. What started as a smaller organisation with only seven employees has now grown to be a productive, innovative company with more than 40 employees. Since October 2018, Glenmark South Africa, under new and experienced leadership, has seen numerous promising developments in its existing and future portfolio, both in the dermatology and respiratory areas. Expanding on the company’s specialty in the dermatology arena, Glenmark South Africa is pleased to announce that they are the first company to launch a generic Imiquimod Cream (5 %) in South Africa. This marks the 1st of a very strong dermatology pipeline to follow in the coming years.",
    link: "",
    url: "https://skincancerfoundation.nexstep-events.co.za/",
  },
  {
    id: 1,
    img: "../images/portfolio/ppc-1.jpg",
    secondimg: "../images/portfolio/ppc-2.jpg",
    title: "COMMUNICATIONS SPECIALIST LTD",
    category: "Pay Per Services",
    desc: "The client approached Talent Individuals looking for support with their social media presence and to increase the number of leads from the UK market.  ",
    link: "",
    url: "https://www.comms-spec.com/",
  },
  {
    id: 1,
    img: "../images/portfolio/social-1.jpg",
    secondimg: "../images/portfolio/social-2.jpg",
    title: "Dream Doors",
    category: "Social Media",
    desc: "Blue Frontier have worked with Dream Doors for a number of years, providing an all-inclusive digital marketing package as well as web design and development services. ",
  },
];
