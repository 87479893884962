import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
const Testimonials = () => {
  return (
    <section className="section testimonial">
      <div className="container">
        <div className="columns is-desktop">
          <div className="column is-8-desktop">
            <div className="heading">
              <h2 className="mb-5">What they say about us</h2>
              <p>
                We've worked on so many great projects for our clients and we're
                proud of every single one.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="columns is-multiline">
          <div className="column is-12 testimonial-wrap">
            <div className="testimonial-block">
              <p>
                Talentindividuals was phenomenal to work with. they redesigned
                my website to make it more efficient. Glad to have the
                opportunity to work with them.
              </p>

              <div className="client-info is-flex is-align-items-center">
                <div className="client-img">
                  <img
                    src="images/team/testimonial1.jpg"
                    alt=""
                    className="w-100"
                  />
                </div>
                <div className="info">
                  <h6>brodricknettles</h6>
                  <span>United States</span>
                </div>
              </div>
            </div>
            <div className="testimonial-block">
              <p>
                Talentindividuals is Great and have a full command of expertise.
                Very humble, listen and implemented all the modifications and
                the best part is response was very quick. I'm hiring them for
                long term bases. Thanks
              </p>

              <div className="client-info is-flex is-align-items-center">
                <div className="client-img">
                  <img
                    src="images/team/testimonial2.jpg"
                    alt=""
                    className="w-100"
                  />
                </div>
                <div className="info">
                  <h6>sohailchpk</h6>
                  <span>Pakistan</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
