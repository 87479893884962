import AliceCarousel from "react-alice-carousel";
// import "react-alice-carousel/lib/alice-carousel.css";
import { Link } from "react-router-dom";
const CaseStudy = ({ title, data }) => {
  const responsiveSettings = {
    0: {
      items: 1,
    },
    580: {
      items: 3,
    },
  };
  return (
    <>
      {data.length === 0 ? null : (
        <section className="section portfolio pb-0">
          <div className="container">
            <div className="columns">
              <div className="column is-8-desktop">
                <div className="heading">
                  <h2 className="mb-5">Check Our {title} Service Portfolio</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="columns is-desktop portfolio-gallery">
              <AliceCarousel
                mouseTracking
                infinite
                autoPlayInterval={5000}
                animationDuration={1500}
                disableDotsControls
                disableButtonsControls
                responsive={responsiveSettings}
                autoPlay
              >
                {data.map((item) => (
                  <div className="column is-12-desktop" key={item.id}>
                    <div className="portflio-item is-relative mb-4">
                      <Link to={`/portfolio-details/${item.title}`}>
                        <img src={item.img} alt="" className="w-100" />
                        <div className="overlay-item">
                          <i className="ti-link"></i>
                        </div>

                        <div className="portfolio-item-content">
                          <p className="text-white-50">{item.desc}</p>
                          <h3 className="mb-0 text-white">{item.title}</h3>
                        </div>
                      </Link>
                    </div>
                  </div>
                ))}
              </AliceCarousel>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default CaseStudy;
